import { Box, IconButton, Radio } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { balanceModel } from '~/entities/Balance'
import { PayCardAttributes } from '~/shared/api'
import { EmptyList } from '~/shared/ui/BalanceList'
import { TrashIcon } from '~/shared/ui/icons'
import { PayCardContent, PayCardWrapper } from '~/shared/ui/PayCard'
import { SkeletonList } from '~/shared/ui/SkeletonList'
import { WrapperCard } from './styled'
import { AddCardButton } from './ui/AddCardButton'
import { DeleteCardDrawer } from './ui/DeleteCardDrawer'

export function ListBankCards() {
  const isPayCardsPending = useStore(balanceModel.getPayCardsFx.pending)
  const isPayCardDeleting = useStore(balanceModel.payCardRemoveFx.pending)

  const payCards = useStoreMap(balanceModel.$payCards, (payCards) =>
    payCards?.map((payCard) => payCard.getAttributes()),
  )
  const selectedPayCard = useStoreMap(
    balanceModel.$selectedPayCard,
    (payCard) => payCard?.getAttributes(),
  )
  const isDrawerShow = useStoreMap(
    balanceModel.$payCardAttributesForDeleting,
    (payCard) => Boolean(payCard),
  )

  const handleChangeSelectedPayCard = (bankCardId: UniqueId) => {
    balanceModel.changeSelectedPayCard(bankCardId)
  }

  const handleDeleteDrawerOpen = (payCard: PayCardAttributes) => {
    balanceModel.deleteCardDrawerOpen(payCard)
  }

  if (isPayCardsPending)
    return (
      <SkeletonList count={6} skeletonHeight={56} itemMarginBottom='16px' />
    )

  return (
    <Box>
      {!payCards?.length && (
        <EmptyList
          title='У вас еще не добавлены банковские карты'
          description='Здесь будет отображаться список банковских карт'
        />
      )}
      {(payCards || []).map((card) => (
        <WrapperCard key={card.bankCardId}>
          <PayCardWrapper>
            <Radio
              checked={card.bankCardId === selectedPayCard?.bankCardId}
              sx={{ padding: 0 }}
              color='midnightBlue'
              onClick={() => handleChangeSelectedPayCard(card.bankCardId)}
              data-testid={`radio-pay-card-${card.maskedNumber}`}
            />

            <PayCardContent cardNumber={card.maskedNumber} />

            <IconButton
              data-testid={`delete-bank-card-${card.maskedNumber}`}
              onClick={() => handleDeleteDrawerOpen(card)}
            >
              <TrashIcon />
            </IconButton>
          </PayCardWrapper>
        </WrapperCard>
      ))}

      <AddCardButton />

      <DeleteCardDrawer
        isLoading={isPayCardDeleting}
        isShow={isDrawerShow}
        onClose={balanceModel.deleteCardDrawerClose}
        onDelete={balanceModel.payCardRemove}
      />
    </Box>
  )
}
