export function PhoneIcon() {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='18' fill='#021542' />
      <path
        d='M17.7979 26.8266C12.4194 20.7554 11.9256 16.7171 17.0122 10.1221C17.1574 9.93379 17.4187 9.87352 17.631 9.98069L20.4628 11.4104C20.6886 11.5244 20.7937 11.7893 20.7075 12.0272L19.6221 15.0201C19.5533 15.2099 19.3772 15.3396 19.1755 15.3491L17.3816 15.4333C17.193 15.4422 17.0244 15.557 16.9506 15.7308C16.0537 17.8427 16.0929 19.0771 17.2142 21.0086C17.2951 21.148 17.4408 21.2372 17.6014 21.2496L19.505 21.3973C19.6839 21.4112 19.8416 21.5199 19.9183 21.6821L21.2977 24.5992C21.4059 24.828 21.3261 25.1015 21.1119 25.2362L18.4362 26.9188C18.2306 27.0482 17.959 27.0084 17.7979 26.8266Z'
        stroke='#E4ECFF'
        strokeWidth='1.2'
        strokeLinecap='round'
      />
    </svg>
  )
}
