export const stories = [
  {
    id: '1',
    title: 'С нами надежно и безопасно',
    img: '/images/stories/MainWorkWithUs.jpg',
    description:
      'Компания на рынке более 7 лет. <br /><br />' +
      'Мы гарантируем: <br />' +
      '- Честную аренду без депозитов и залогов <br />' +
      '- Первый день аренды для новичков в подарок <br />' +
      '- Бесплатное детское кресло <br /><br />' +
      'Компания является генеральным партнером Яндекс. Такси  <br /><br />' +
      'Мы предоставляем:  <br />' +
      '- автомобили с лицензией для работы такси и полисом ОСАГО с галочкой «Такси» <br />' +
      '- собственные станции технического обслуживания <br />' +
      '- собственные сертифицированные медики и механики <br />' +
      '- круглосуточную поддержку водителей 24/7 <br />' +
      '- возможность моментального вывода денежных средств на карту 24/7 <br /><br />',
  },
  {
    id: '2',
    title: 'Клуб водителей 369',
    img: '/images/stories/MainRentCar.jpg',
    description:
      'Мы создали закрытое сообщество водителей парка 369, которое позволит вам:<br />' +
      '1. Узнавать первым о новостях из жизни компании <br />' +
      '2. Быть в открытом диалоге с коллегами и руководством парка <br />' +
      '3. Принимать участие в изменениях условий работы, вносить свои предложения, задавать “неудобные вопросы” и получать на них ответы <br />' +
      '4. Решать любые свои вопросы, связанные с работой в парке <br />' +
      '5. Получить поддержку коллег, обменяться опытом, расширить круг общения <br />' +
      '6. Принимать участие в спортивных и закрытых мероприятиях, розыгрышах, акциях от парка <br /><br />' +
      'Вступить в Клуб 369 возможно только по индивидуальному приглашению, которое мы отправили вам с номера TAXI 369 в день оформления автомобиля в аренду. <br /><br />' +
      'Подробнее вы всегда можете узнать в Службе поддержки водителей или у Начальника колонны. <br /><br />',
  },
  // {
  //   id: '3',
  //   title: 'Связь с оператором',
  //   img: '/images/stories/MainCommunicationWithOperator.jpg',
  //   description:
  //     'На дороге вы можете столкнуться с неприятными ситуациями: ДТП, поломка, блокировка авто.<br /><br />' +
  //     'Чтобы разобраться с проблемой можно обратиться за помощью к операторам нашего парка.<br /><br />' +
  //     'Операторы будут рады помочь вам, но постарайтесь не злоупотреблять функцией связи. На большинство вопросов мы ответили в разделе «Поддержка». ',
  // },
]
