import { LoadingButton, LoadingButtonTypeMap } from '@mui/lab'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import React, { FunctionComponent, ReactNode, useState } from 'react'
import { logger } from '~/shared/lib/logger'
import { Dialog } from './styled'

type ModalProps = {
  children: ReactNode
  isShow: boolean
  ok?: string
  onOk?: () => Promise<void> | void
  okButtonProps?: Partial<LoadingButtonTypeMap['props']>
  cancelButtonProps?: Partial<LoadingButtonTypeMap['props']>
  onCancel?: () => void
  cancel?: string
  canBackdropClose?: boolean
  sx?: SxProps<Theme>
}
export const Modal: FunctionComponent<ModalProps> = ({
  isShow,
  children,
  ok = 'Хорошо',
  onOk,
  okButtonProps,
  cancelButtonProps,
  onCancel,
  cancel = 'Отмена',
  canBackdropClose = true,
  sx,
}) => {
  const [loading, toggleLoading] = useState(false)

  const handleOnClose = () => {
    if (loading) return
    onCancel?.()
  }

  const handleOnOk = async () => {
    try {
      toggleLoading(true)
      await onOk?.()
    } catch (e) {
      logger.error('Modal send error:', e)
    } finally {
      toggleLoading(false)
    }
  }

  return (
    <Dialog
      open={isShow}
      onClose={canBackdropClose ? handleOnClose : undefined}
      data-testid={'modal'}
      keepMounted={false}
      disableAutoFocus
      sx={sx}
    >
      {children}

      {(onOk || onCancel) && <br />}

      {onOk && (
        <LoadingButton
          sx={{ width: '100%', ...(onCancel ? { mb: '16px' } : {}) }}
          variant='limeFlooded'
          size='large'
          loading={loading}
          onClick={handleOnOk}
          data-testid={`submit-modal-button-${ok}`}
          {...okButtonProps}
        >
          {ok}
        </LoadingButton>
      )}

      {onCancel && (
        <LoadingButton
          color='inherit'
          size='large'
          onClick={handleOnClose}
          sx={{ width: '100%' }}
          disabled={loading}
          {...cancelButtonProps}
        >
          {cancel}
        </LoadingButton>
      )}
    </Dialog>
  )
}
