import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { Box } from '@mui/material'
import { myPalette } from '../theme'

export function MenuLogoutIcon() {
  return (
    <Box
      sx={{
        p: '8px',
        borderRadius: '100%',
        background: myPalette.midnightBlue.main,
        display: 'flex',
      }}
    >
      <ExitToAppIcon color='white' fontSize='small' />
    </Box>
  )
}
