import * as z from 'zod'

import { ApiModel } from './core'

const schema = z.object({
  amount: z.number(),
  commissionPercent: z.number(),
  commissionAmount: z.number(),
  amountTotal: z.number(),
})

export type PayCommissionAttributes = z.infer<typeof schema>

export class PayCommission extends ApiModel<
  typeof schema,
  PayCommissionAttributes
> {
  static jsonApiType = 'commission'

  static schema = schema

  static async commission(amount: number): Promise<PayCommissionAttributes> {
    const url = `${PayCommission.getJsonApiUrl()}/balance-refill/calculate`
    const response = await PayCommission.effectiveHttpClient.post(url, {
      amount,
    })
    return response?.getData()
  }
}
