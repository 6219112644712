import { LoadingButton } from '@mui/lab'
import { Unstable_Grid2 as Grid } from '@mui/material'
import { useStore } from 'effector-react'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormContainer } from 'react-hook-form-mui'
import { TextFieldMaskElement, useDefaultFieldProps } from '~/shared/ui/form'
import { requestLoginCodeFx } from '../model'
import { LoginStepTitle } from '../styled'
import { LoginPhoneFormValues } from './model'

type StepLoginPhoneProps = {
  form: UseFormReturn<LoginPhoneFormValues>
  onSuccess: () => Promise<void>
}

export function StepLoginPhone({ form, onSuccess }: StepLoginPhoneProps) {
  const isRequestLoginCodePending = useStore(requestLoginCodeFx.pending)
  const { textFieldMaskProps } = useDefaultFieldProps(isRequestLoginCodePending)

  return (
    <FormContainer formContext={form} onSuccess={onSuccess}>
      <LoginStepTitle>
        Авторизуйтесь, чтобы иметь полный доступ к функционалу
      </LoginStepTitle>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid xs={12}>
          <TextFieldMaskElement
            name='phone'
            label='Номер телефона'
            mask='+7 (999) 999-99-99'
            size='small'
            {...textFieldMaskProps}
          />
        </Grid>
        <Grid xs={12}>
          <LoadingButton
            variant='limeFlooded'
            size='large'
            loading={isRequestLoginCodePending}
            fullWidth
            type='submit'
          >
            Получить код
          </LoadingButton>
        </Grid>
      </Grid>
    </FormContainer>
  )
}
