import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'
import { NumericFormatElement as DefaultNumericFormatElement } from '~/shared/ui/form'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const NumericFormatElement = styled(DefaultNumericFormatElement)`
  padding-right: 0;
  margin-bottom: 60px;

  .MuiInputBase-root {
    padding: 0;
    background-color: ${myPalette.white.main};
  }

  .MuiFormLabel-root {
    background: transparent;
  }
`

export const RemoveAllButton = styled(LoadingButton)`
  width: 134px;
  margin-right: 6px;
`
