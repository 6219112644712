import { createDomain, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { interval } from 'patronum'
import { AxiosErrorType, RentalContact } from '~/shared/api'
import { isDevEnv } from '~/shared/config/env'
import { snackbarEnqueued } from '~/shared/lib/notifications'

export const Gate = createGate()
const domain = createDomain('entities.mainCar')

const getRentalContract = domain.createEvent()
export const getRentalContractFx = domain.createEffect<
  void,
  RentalContact,
  AxiosErrorType
>({
  handler: RentalContact.my,
})

export const getRentalContractWithoutLoadingFx = domain.createEffect({
  handler: RentalContact.my,
})

export const $rentalContract = createStore<RentalContact | null>(null)
  .on(
    [getRentalContractFx.doneData, getRentalContractWithoutLoadingFx.doneData],
    (_, rentalContract) => rentalContract,
  )
  .on(
    [
      getRentalContractFx.failData,
      getRentalContractWithoutLoadingFx.failData,
      Gate.close,
    ],
    () => null,
  )

sample({
  clock: Gate.open,
  target: getRentalContract,
})

sample({
  clock: getRentalContract,
  target: getRentalContractFx,
})

sample({
  clock: getRentalContractFx.failData,
  filter: (e) => e?.response?.status !== 404,
  fn() {
    return {
      message: 'Ошибка получения договора аренды',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

const intervalRequestStarted = domain.createEvent()
const intervalRequestStopped = domain.createEvent()

if (!isDevEnv) {
  const { tick } = interval({
    timeout: 60000,
    start: intervalRequestStarted,
    stop: intervalRequestStopped,
    leading: false,
    trailing: false,
  })

  sample({
    clock: tick,
    target: getRentalContractWithoutLoadingFx,
  })

  sample({
    clock: Gate.open,
    target: intervalRequestStarted,
  })
  sample({
    clock: Gate.close,
    target: intervalRequestStopped,
  })
}
