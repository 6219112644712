import { CURRENCY } from '~/shared/config/constants'
import { Modal } from '~/shared/ui/Modal'
import { Li, Sum } from './styled'

type PayInfoModalProps = {
  isShow: boolean
  onCancel?: () => void
}

export function PayInfoModal({ isShow, onCancel }: PayInfoModalProps) {
  return (
    <Modal isShow={isShow} onOk={onCancel}>
      <ul>
        <Li>
          К снятию доступно не более <Sum>18 600 {CURRENCY}</Sum> в сутки
        </Li>
        <Li>
          Неснижаемый остаток на счету — <Sum>500 {CURRENCY}</Sum>
        </Li>
        <Li>Остаток станет доступен к выводу через 14 дней после увольнения</Li>
      </ul>
    </Modal>
  )
}
