import { Box, styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Wrapper = styled(Box)`
  text-align: center;
  margin: 113px 0;

  p {
    color: ${myPalette.white.main};
  }
`
