import { ImageList as MuiImageList } from '@mui/material'
import { stories } from './constants'
import { Title, ImageListItem } from './styled'

type ListProps = {
  onClick: (storyId: UniqueId) => void
}

export function ImageList({ onClick }: ListProps) {
  return (
    <MuiImageList cols={2} gap={16} sx={{ margin: 0 }}>
      {stories.map((item) => (
        <ImageListItem
          key={item.id}
          onClick={() => onClick(item.id)}
          data-testid='story-item'
        >
          <Title>{item.title}</Title>
          <img src={item.img} alt={item.title} loading='lazy' />
        </ImageListItem>
      ))}
      <br />
    </MuiImageList>
  )
}
