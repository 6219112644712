import { Skeleton, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { payModel } from '~/entities/Balance'
import { CURRENCY } from '~/shared/config/constants'
import { Wrapper } from './styled'

type CommissionProps = {
  isLoading: boolean
}

export function Commission({ isLoading }: CommissionProps) {
  const commission = useStore(payModel.$payCommission)

  return (
    <Wrapper>
      <Typography>Комиссия</Typography> —{' '}
      {isLoading ? (
        <Skeleton variant='rounded' height={24} width={70} sx={{ ml: '4px' }} />
      ) : (
        <span>
          {commission?.commissionAmount || 0} {CURRENCY}
        </span>
      )}
    </Wrapper>
  )
}
