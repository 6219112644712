import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import { ImageList, StoryModal } from '~/entities/Story'

export function StoryList() {
  const [storyId, setStoryId] = useState<string>()

  const handleOpenStory = (storyId: UniqueId) => {
    setStoryId(storyId)
  }
  const handleStoryClose = () => {
    setStoryId(undefined)
  }

  return (
    <Box data-testid='story-list-block'>
      <Typography
        fontWeight={700}
        fontSize={18}
        color='midnightBlue.main'
        mb='24px'
      >
        Информация
      </Typography>
      <ImageList onClick={handleOpenStory} />
      <StoryModal storyId={storyId} onClose={handleStoryClose} />
    </Box>
  )
}
