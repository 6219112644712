import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { ReactNode } from 'react'
import { useForm } from 'react-hook-form'
import { FormContainer } from 'react-hook-form-mui'
import { sanitizePriceNumber } from '~/shared/lib/utils'
import { FormValues, paySchema } from './model'
import { NumericFormatElement } from './styled'

type FormProps = {
  isSubmitting: boolean
  onSuccess: () => void
  onSumChange: (sum?: number) => void
  disable: boolean
  additionalContent?: ReactNode
}

export function PayForm({
  isSubmitting,
  onSuccess,
  onSumChange,
  disable,
  additionalContent,
}: FormProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(paySchema),
  })

  const { watch } = form

  watch((data, { name, type }) => {
    if (type !== 'change') return
    if (name === 'amount') {
      onSumChange(sanitizePriceNumber(data.amount || ''))
    }
  })

  return (
    <FormContainer formContext={form} onSuccess={onSuccess}>
      <NumericFormatElement
        name='amount'
        label='Сумма для пополнения'
        fullWidth
        suffix=' руб'
        InputProps={{
          inputProps: {
            inputMode: 'numeric',
          },
        }}
      />

      {additionalContent}

      <LoadingButton
        fullWidth
        type='submit'
        variant='limeFlooded'
        size='large'
        loading={isSubmitting}
        disabled={disable}
        data-testid='pay-drawer-submit'
      >
        Пополнить
      </LoadingButton>
    </FormContainer>
  )
}
