import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Wrapper = styled(Link)`
  box-shadow: 0 0 5px ${myPalette.dark.shadowLight};
  background-color: ${myPalette.white.main};
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  text-decoration: none;
  padding: 16px;
`

export const TitleWrapper = styled(Typography)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

export const Title = styled('span')`
  font-size: 14px;
  line-height: 18px;
  color: ${myPalette.dark.main};
`

export const Img = styled('img')`
  width: 36px;
  height: 36px;
  margin-right: 12px;
`

export const NavText = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: ${myPalette.midnightBlue.main};
`
