import { createDomain, sample } from 'effector'
import { useStore } from 'effector-react'

import { authStore, http, User } from '~/shared/api'
import { pushFx } from '~/shared/lib/history'

export const domain = createDomain('entities.viewer')

/* Save token */
export const tokenSaved = domain.createEvent<string>()
export const saveTokenFx = domain.createEffect<string, void>({
  async handler(token) {
    authStore.saveToken(token)
  },
})
sample({
  clock: tokenSaved,
  target: saveTokenFx,
})

/* Clear token */
export const tokenCleared = domain.createEvent()
export const clearTokenFx = domain.createEffect({
  async handler() {
    authStore.clearToken()
  },
})
sample({
  clock: tokenCleared,
  target: clearTokenFx,
})

http.onUnauthorize(() => {
  tokenCleared()
})

/* Token */
export const $token = domain
  .createStore<string | null>(authStore.token)
  .on(tokenSaved, (_, token) => token)
  .on(tokenCleared, () => null)

export const $authenticated = $token.map<boolean>((token) => Boolean(token))

/* Sign-in/Sign-out */
export const signIn = domain.createEvent()
sample({
  clock: signIn,
  fn() {
    return '/login'
  },
  target: pushFx,
})

export const signOut = domain.createEvent()
export const logoutFx = domain.createEffect({
  async handler() {
    return User.logout()
  },
})

sample({
  clock: signOut,
  target: logoutFx,
})

sample({
  clock: logoutFx.doneData,
  target: tokenCleared,
})

/* Hooks */
export const useAuthenticated = () => useStore($authenticated)
