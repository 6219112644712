import { useStore } from 'effector-react'
import { balanceModel } from '~/entities/Balance'
import { PlusIcon } from '~/shared/ui/icons'
import { Button } from './styled'

export function AddCardButton() {
  const isGetAddLinkLoading = useStore(balanceModel.getAddLinkFx.pending)
  const addLink = useStore(balanceModel.$addLink) || ''

  return (
    <Button
      href={addLink}
      fullWidth
      variant='lightSteelBlueBorder'
      startIcon={<PlusIcon />}
      size='large'
      loading={isGetAddLinkLoading}
      loadingPosition='end'
      disabled={!addLink}
    >
      Добавить карту
    </Button>
  )
}
