import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Unstable_Grid2 as Grid } from '@mui/material'
import { useStoreMap } from 'effector-react'
import React from 'react'
import { mainCarModel } from '~/entities/MainCar'
import {
  CURRENCY,
  rentalContractStatusEnumLabels,
} from '~/shared/config/constants'
import { myPalette } from '~/shared/ui/theme'
import {
  Chip,
  DataWrapper,
  Typography,
  Block,
  BlockTitle,
  BlockDescription,
  BlockTariff,
} from './styled'

export function Descriptions() {
  const rentalContract = useStoreMap(
    mainCarModel.$rentalContract,
    (rentalContract) => rentalContract?.getAttributes(),
  )
  const status = rentalContract?.status
    ? rentalContractStatusEnumLabels[rentalContract.status]
    : null

  return (
    <>
      <DataWrapper>
        <Box>
          <Typography
            pb='8px'
            fontSize={18}
            fontWeight={500}
            data-testid='main-car-model'
          >
            {rentalContract?.carBrandTitle} {rentalContract?.carModelTitle}
          </Typography>
          <Chip
            data-testid='main-car-plate-number'
            label={rentalContract?.carPlateNumber}
            variant='outlined'
            size='small'
          />
        </Box>
        <BlockTariff>
          <Typography fontSize={16} data-testid='main-car-tariff'>
            Тариф &quot;{rentalContract?.carCategoryName}&quot;
          </Typography>
          <ChevronRightIcon color='white' />
        </BlockTariff>
      </DataWrapper>
      <Grid container spacing={2}>
        <Grid xs={4}>
          <Block>
            <BlockTitle>График</BlockTitle>
            <BlockDescription data-testid='main-car-work-schedule'>
              {rentalContract?.workSchedule}
            </BlockDescription>
          </Block>
        </Grid>
        <Grid xs={8}>
          <Block
            sx={{
              ...(status ? { background: myPalette.coral.main } : {}),
            }}
          >
            {status ? (
              <Typography>
                Договор{' '}
                <Typography textTransform='lowercase' display='inline'>
                  {status}
                </Typography>
              </Typography>
            ) : (
              <>
                <BlockTitle>Стоимость аренды</BlockTitle>
                <BlockDescription data-testid='main-car-rent-per-day'>
                  {rentalContract?.rentPerDay &&
                    Number(rentalContract?.rentPerDay).toLocaleString()}
                  {CURRENCY} / сутки
                </BlockDescription>
              </>
            )}
          </Block>
        </Grid>
      </Grid>
    </>
  )
}
