import { useStore } from 'effector-react'
import {
  TabsDrawer,
  PassportDetailsDrawer,
  DriverLicenseDrawer,
} from '~/entities/Profile'
import { useDrawer } from '~/shared/ui/Drawer'
import { $isProfileDrawerOpen, closeProfileDrawer } from './model'

export function Profile() {
  const isProfileOpen = useStore($isProfileDrawerOpen)
  const {
    openDrawer: passportDetailsDrawerOpen,
    closeDrawer: passportDetailsDrawerClose,
    isDrawerShow: isPassportDetailsDrawerShow,
  } = useDrawer()
  const {
    openDrawer: driverLicenseOpen,
    closeDrawer: driverLicenseClose,
    isDrawerShow: isDriverLicenseShow,
  } = useDrawer()

  return (
    <>
      <TabsDrawer
        isShow={isProfileOpen}
        onClose={closeProfileDrawer}
        onPassportDetailsClick={passportDetailsDrawerOpen}
        onDriverLicenseClick={driverLicenseOpen}
      />
      <PassportDetailsDrawer
        isShow={isPassportDetailsDrawerShow}
        onClose={passportDetailsDrawerClose}
      />
      <DriverLicenseDrawer
        isShow={isDriverLicenseShow}
        onClose={driverLicenseClose}
      />
    </>
  )
}
