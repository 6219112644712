import { Box, styled } from '@mui/material'

export const ActionsBlock = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TitleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`
