import { IonModal as IonicIonModal } from '@ionic/react'
import MuiCloseIcon from '@mui/icons-material/Close'
import { Box, styled } from '@mui/material'
import { BODY_MAX_WIDTH, myPalette } from '~/shared/ui/theme'

export const CloseIcon = styled(MuiCloseIcon)`
  position: absolute;
  right: 14px;
  top: 14px;
  font-size: 44px;
  color: ${myPalette.dark.main};
  cursor: pointer;
  z-index: 6;
`

export const Img = styled('img')`
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  cursor: pointer;
  z-index: -1;
`

export const Wrapper = styled(Box)`
  position: relative;
  overflow-y: auto;
  overscroll-behavior: none;
  display: flex;
  flex-direction: column;
`

export const ContentWrapper = styled(Box)`
  padding: 16px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: ${myPalette.aliceBlue.main};
  margin-top: -26px;
  position: relative;
  z-index: 1;
  flex: 1;
`

export const Title = styled(Box)`
  font-size: 22px;
  font-weight: 700;
  color: ${myPalette.midnightBlue.main};
  margin-bottom: 24px;
`

export const Description = styled(Box)`
  font-size: 18px;
  font-weight: 400;
  color: ${myPalette.midnightBlue.main};
`
export const IonModal = styled(IonicIonModal)`
  --border-radius: 20px !important;
  --max-width: ${BODY_MAX_WIDTH};
`
