import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { interval } from 'patronum'
import { AxiosErrorType, Balance, BalanceReport, Customer } from '~/shared/api'
import { isDevEnv } from '~/shared/config/env'
import { formatForApi } from '~/shared/lib/date'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

export const Gate = createGate()

const domain = createDomain('entities.mainBalance')

export const getCustomerMeWithoutLoadingFx = domain.createEffect<
  void,
  void,
  AxiosErrorType
>({
  handler: Customer.me,
})

export const getCustomerMeFx = domain.createEffect<void, void, AxiosErrorType>({
  handler: Customer.me,
})

sample({
  clock: Gate.open,
  target: getCustomerMeFx,
})

export const $customerMeError = domain
  .createStore<string | null>(null)
  .on(
    [getCustomerMeFx.failData, getCustomerMeWithoutLoadingFx.failData],
    (_, e) => mapMessageErrors(e),
  )
  .on(
    [
      getCustomerMeFx.doneData,
      getCustomerMeWithoutLoadingFx.doneData,
      Gate.close,
    ],
    () => null,
  )

const getCurrentBalance = domain.createEvent()
export const getCurrentBalanceFx = domain.createEffect({
  handler: Balance.my,
})
export const getCurrentBalanceWithoutLoadingFx = domain.createEffect({
  handler: Balance.my,
})

sample({
  clock: getCustomerMeFx.doneData,
  target: getCurrentBalance,
})

export const $currentBalance = domain
  .createStore<Balance | null>(null)
  .on(
    [getCurrentBalanceFx.doneData, getCurrentBalanceWithoutLoadingFx.doneData],
    (_, balance) => balance,
  )
  .on(Gate.close, () => null)

sample({
  clock: getCurrentBalance,
  target: getCurrentBalanceFx,
})

sample({
  clock: getCurrentBalanceFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получения данных по балансу',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

// BalanceReport
const fetchBalanceReport = async () => {
  const currentDate = formatForApi(new Date())
  return await BalanceReport.my(currentDate)
}

const getCurrentBalanceReport = domain.createEvent()
export const getCurrentBalanceReportFx = domain.createEffect({
  handler: fetchBalanceReport,
})
export const getCurrentBalanceReportWithoutLoadingFx = domain.createEffect({
  handler: fetchBalanceReport,
})

sample({
  clock: Gate.open,
  target: getCurrentBalanceReportFx,
})

export const $currentBalanceReport = domain
  .createStore<BalanceReport | null>(null)
  .on(
    [
      getCurrentBalanceReportFx.doneData,
      getCurrentBalanceReportWithoutLoadingFx.doneData,
    ],
    (store, balance) => balance,
  )
  .on(Gate.close, () => null)

sample({
  clock: getCurrentBalanceReport,
  target: getCurrentBalanceReportFx,
})

sample({
  clock: getCurrentBalanceReportFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получения данных по отчету текущего баланса',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

const intervalRequestStarted = domain.createEvent()
const intervalRequestStopped = domain.createEvent()

if (!isDevEnv) {
  const { tick } = interval({
    timeout: 30000,
    start: intervalRequestStarted,
    stop: intervalRequestStopped,
    leading: false,
    trailing: false,
  })

  sample({
    clock: tick,
    target: getCustomerMeWithoutLoadingFx,
  })

  sample({
    clock: tick,
    source: $customerMeError,
    filter: (customerMeError) => !customerMeError,
    target: [
      getCurrentBalanceWithoutLoadingFx,
      getCurrentBalanceReportWithoutLoadingFx,
    ],
  })

  sample({
    clock: Gate.open,
    target: intervalRequestStarted,
  })
  sample({
    clock: Gate.close,
    target: intervalRequestStopped,
  })
}
