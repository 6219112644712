export enum TransmissionEnum {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export enum FuelTypeEnum {
  BENZINE = 'BENZINE',
  DIESEL = 'DIESEL',
  METHANE = 'METHANE',
  PROPANE = 'PROPANE',
  ELECTRICITY = 'ELECTRICITY',
}

export enum CarTariffEnum {
  ECONOMY = 'ECONOMY',
  BUSINESS = 'BUSINESS',
}

export enum ScheduleTypeEnum {
  SEVEN_ZERO = 'SEVEN_ZERO',
  SIX_ONE = 'SIX_ONE',
}

export enum RentalStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
}

export enum AcceptanceDocumentTypeEnum {
  DriverPersonalData = 'DriverPersonalData',
  PublicOffer = 'PublicOffer',
}

export enum MoveFundsType {
  COMING = 'COMING',
  SPENDING = 'SPENDING',
}

export enum RentalContractStatusEnum {
  SUSPENDED = 'SUSPENDED',
}
