import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { Media } from '~/shared/api'

const domain = createDomain('penalty-drawer')
export const Gate = createGate<{ uuid: string; url: string }[]>()

export const fetchImageFx = domain.createEffect<
  { uuid: string; url: string }[],
  Blob[]
>({
  handler: (files) => {
    const promises = files.map(({ uuid }) => Media.show(uuid))
    return Promise.all(promises)
  },
})

export const $photos = domain
  .createStore<{ id: UniqueId; src: string }[]>([])
  .on(fetchImageFx.doneData, (_, blobs) =>
    blobs.map((blob) => {
      const src = URL.createObjectURL(blob)
      return {
        id: src,
        src,
      }
    }),
  )
  .on(Gate.close, () => [])

sample({
  clock: Gate.open,
  filter: (files) => Boolean(files?.length),
  target: fetchImageFx,
})
