import { Button, Typography } from '@mui/material'
import Slide from '@mui/material/Slide'
import React from 'react'

import { Wrapper, ActionsBlock, Snackbar } from './styled'

type Notification = {
  id: UniqueId
  title: string
  description: string
  actions?: string
}

type NotificationProps = {
  onNotificationsOpen: () => void
  onClose: (notification: Notification) => void
  isShow: boolean
  notification?: Notification
}

export function Notification({
  onNotificationsOpen,
  isShow,
  onClose,
  notification,
}: NotificationProps) {
  const handleOnClose = () => onClose(notification as Notification)

  const handleNotificationsOpen = () => {
    handleOnClose()
    onNotificationsOpen()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isShow}
      TransitionComponent={Slide}
    >
      <Wrapper>
        <Typography
          fontSize={18}
          fontWeight={700}
          mb='4px'
          color='midnightBlue.main'
        >
          {notification?.title}
        </Typography>
        <Typography
          fontSize={16}
          color='midnightBlue.main'
          lineHeight='24px'
          mb='16px'
        >
          {notification?.description}
        </Typography>
        <ActionsBlock>
          <Button onClick={handleNotificationsOpen}>
            <Typography fontSize={16} fontWeight={500} color='blue.main'>
              Подробнее
            </Typography>
          </Button>
          <Button onClick={handleOnClose}>
            <Typography fontSize={16} fontWeight={500} color='blue.main'>
              Скрыть
            </Typography>
          </Button>
        </ActionsBlock>
      </Wrapper>
    </Snackbar>
  )
}
