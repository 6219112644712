import { useEffect, useState } from 'react'
import { stories } from './constants'
import {
  Description,
  Img,
  Title,
  Wrapper,
  CloseIcon,
  IonModal,
  ContentWrapper,
} from './styled'

type StoryModalProps = {
  onClose: () => void
  storyId?: UniqueId
}

export function StoryModal({ onClose, storyId }: StoryModalProps) {
  const isShow = Boolean(storyId)
  const [story, setStory] = useState<{
    img: string
    title: string
    description: string
  }>()

  useEffect(() => {
    if (storyId) {
      setStory(stories.find((story) => story.id === storyId))
    }
  }, [storyId])

  return (
    <IonModal
      isOpen={isShow}
      keepContentsMounted={false}
      presentingElement={
        document.getElementById('page-container') as HTMLElement
      }
      onDidDismiss={onClose}
    >
      <CloseIcon onClick={onClose} data-testid='story-modal-close-button' />
      <Wrapper>
        {story?.img && <Img src={story.img} alt={story.title} />}
        <ContentWrapper>
          <Title data-testid='story-modal-title'>{story?.title}</Title>
          <Description
            dangerouslySetInnerHTML={{ __html: story?.description || '' }}
          />
        </ContentWrapper>
      </Wrapper>
    </IonModal>
  )
}
