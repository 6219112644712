import { IonRouterOutlet } from '@ionic/react'
import { Box } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { Penalties } from 'src/widgets/Penalties'
import { Notifications } from '~/widgets/Notifications'
import { Profile, profileModel } from '~/widgets/Profile'
import { Proposal, proposalModel } from '~/widgets/Proposal'
import { Support } from '~/widgets/Support'
import { mainBalance } from '~/entities/MainBalance'
import { mainCarModel } from '~/entities/MainCar'
import { ErrorBoundary } from '~/shared/ui/ErrorBoundary'
import { ProgressBlock } from '~/shared/ui/Loaders'

import { MAIN_LAYOUT_MENU_ID } from '~/shared/ui/theme/theme'
import { Menu } from './Menu'

type Props = {
  children?: ReactNode
}

export function MainLayout({ children }: Props) {
  useGate(profileModel.Gate)
  useGate(mainCarModel.Gate)
  useGate(proposalModel.Gate)
  useGate(mainBalance.Gate)

  const acceptanceNeedPending = useStore(proposalModel.$acceptanceNeedPending)
  const driverProfilePending = useStore(profileModel.$driverProfilePending)

  if (acceptanceNeedPending || driverProfilePending) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ProgressBlock />
      </Box>
    )
  }

  return (
    <ErrorBoundary>
      <Menu />
      <IonRouterOutlet id={MAIN_LAYOUT_MENU_ID}>
        {children || <Outlet />}
      </IonRouterOutlet>
      <Proposal />
      <Profile />
      <Penalties />
      <Notifications />
      <Support />
    </ErrorBoundary>
  )
}
