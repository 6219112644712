import ErrorIcon from '@mui/icons-material/Error'
import { useStore, useStoreMap } from 'effector-react'
import { Link } from 'react-router-dom'
import { mainBalance } from '~/entities/MainBalance'
import { notificationModalOpen } from '~/shared/lib/notificationModal'
import { RubIcon } from '~/shared/ui/icons'
import { ProgressBlock } from '~/shared/ui/Loaders'
import { Chip } from './styled'

type CurrentBalanceProps = {
  disabled?: boolean
}

export function CurrentBalance({ disabled }: CurrentBalanceProps) {
  const amount = useStoreMap(
    mainBalance.$currentBalance,
    (currentBalance) => currentBalance?.getAmount() || 0,
  )

  const customerMeError = useStore(mainBalance.$customerMeError)

  const isGetCurrentBalancePending = useStore(
    mainBalance.getCurrentBalanceFx.pending,
  )
  const isGetCustomerMePending = useStore(mainBalance.getCustomerMeFx.pending)

  const handleShowErrorModal = () => {
    notificationModalOpen({
      title: 'Ошибка!',
      description: customerMeError || '',
      variant: 'error' as const,
    })
  }

  if (isGetCurrentBalancePending || isGetCustomerMePending) {
    return (
      <Chip label={<ProgressBlock height={16} size={16} color='aliceBlue' />} />
    )
  }

  if (customerMeError)
    return (
      <Chip onClick={handleShowErrorModal} icon={<ErrorIcon color='coral' />} />
    )

  return (
    <Link
      to={'/balance'}
      style={{
        textDecoration: 'none',
        ...(disabled ? { pointerEvents: 'none' } : {}),
      }}
    >
      <Chip icon={<RubIcon />} label={amount.toLocaleString()} />
    </Link>
  )
}
