import { useStore } from 'effector-react'
import {
  CarNotStartDrawer,
  DriverSupportServiceDrawer,
  BreakdownOnRoad,
  ParkAddressAndContactsDrawer,
  RoadAccidentDrawer,
  TabsDrawer,
  DocumentsDrawer,
  TrafficPoliceFinesDrawer,
} from '~/entities/Support'
import { useDrawer } from '~/shared/ui/Drawer'
import { $isSupportDrawerOpen, closeSupportDrawer } from './model'

export function Support() {
  const isSupportOpen = useStore($isSupportDrawerOpen)

  const {
    openDrawer: driverSupportServiceOpen,
    closeDrawer: driverSupportServiceClose,
    isDrawerShow: isDriverSupportServiceShow,
  } = useDrawer()
  const {
    openDrawer: parkAddressAndContactsOpen,
    closeDrawer: parkAddressAndContactsClose,
    isDrawerShow: isParkAddressAndContactsShow,
  } = useDrawer()
  const {
    openDrawer: carNotStartOpen,
    closeDrawer: carNotStartClose,
    isDrawerShow: isCarNotStartShow,
  } = useDrawer()
  const {
    openDrawer: roadAccidentOpen,
    closeDrawer: roadAccidentClose,
    isDrawerShow: isRoadAccidentShow,
  } = useDrawer()
  const {
    openDrawer: breakdownOnRoadOpen,
    closeDrawer: breakdownOnRoadClose,
    isDrawerShow: isBreakdownOnRoadShow,
  } = useDrawer()
  const {
    openDrawer: documentsOpen,
    closeDrawer: documentsClose,
    isDrawerShow: isDocumentsShow,
  } = useDrawer()
  const {
    openDrawer: trafficPoliceFinesOpen,
    closeDrawer: trafficPoliceFinesClose,
    isDrawerShow: isTrafficPoliceFinesShow,
  } = useDrawer()

  return (
    <>
      <TabsDrawer
        isShow={isSupportOpen}
        onClose={closeSupportDrawer}
        onDriverSupportService={driverSupportServiceOpen}
        onParkAddressAndContactsOpen={parkAddressAndContactsOpen}
        onCarNotStartOpen={carNotStartOpen}
        onRoadAccidentOpen={roadAccidentOpen}
        onBreakdownOnRoadOpen={breakdownOnRoadOpen}
        onDocumentsOpen={documentsOpen}
        onTrafficPoliceFinesDrawerOpen={trafficPoliceFinesOpen}
      />

      <DriverSupportServiceDrawer
        isShow={isDriverSupportServiceShow}
        onClose={driverSupportServiceClose}
      />
      <ParkAddressAndContactsDrawer
        isShow={isParkAddressAndContactsShow}
        onClose={parkAddressAndContactsClose}
      />
      <CarNotStartDrawer
        isShow={isCarNotStartShow}
        onClose={carNotStartClose}
      />
      <RoadAccidentDrawer
        isShow={isRoadAccidentShow}
        onClose={roadAccidentClose}
      />
      <BreakdownOnRoad
        isShow={isBreakdownOnRoadShow}
        onClose={breakdownOnRoadClose}
      />
      <DocumentsDrawer isShow={isDocumentsShow} onClose={documentsClose} />
      <TrafficPoliceFinesDrawer
        isShow={isTrafficPoliceFinesShow}
        onClose={trafficPoliceFinesClose}
      />
    </>
  )
}
