import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import { Wrapper } from './styled'

type ImageSliderProps = {
  images: {
    id: UniqueId
    src: string
  }[]
  visibleImageId?: string
}

export function ImageSlider({ images, visibleImageId }: ImageSliderProps) {
  const sliderRef = useRef<Slider>(null)

  useEffect(() => {
    if (visibleImageId) {
      sliderRef.current?.slickGoTo(
        images.findIndex(({ id }) => id === visibleImageId),
        true,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleImageId])

  return (
    <Wrapper>
      <Slider
        ref={sliderRef}
        dots
        arrows={false}
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        customPaging={(index: number) => (
          <img src={images[index]?.src} alt='' />
        )}
      >
        {images.map((img) => (
          <img key={img.id} src={img.src} alt='' />
        ))}
      </Slider>
    </Wrapper>
  )
}
