export function ArrowRightIcon() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.5 12H20.5M20.5 12L14.5 6M20.5 12L14.5 18'
        stroke='#021542'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
