import { createDomain, sample } from 'effector'
import { PayCommission, PayCommissionAttributes } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { notificationModalOpen } from '~/shared/lib/notificationModal'
import { payDrawerModel } from './ui/PayDrawer'

const domain = createDomain('entities.balance.pay')

// Commission
export const getPayCommissionFx = domain.createEffect<
  number,
  PayCommissionAttributes
>({
  async handler(amount) {
    return await PayCommission.commission(amount)
  },
})

export const setPayCommission =
  domain.createEvent<PayCommissionAttributes | null>()
export const $payCommission = domain
  .createStore<PayCommissionAttributes | null>(null)
  .on(setPayCommission, (_, commission) => commission)
  .on(payDrawerModel.closePayDrawer, () => null)

sample({
  clock: getPayCommissionFx.failData,
  fn(e) {
    logger.error(e)

    return {
      title: 'Ошибка при получении комиссии!',
      variant: 'error' as const,
    }
  },
  target: notificationModalOpen,
})
