import { IonButton } from '@ionic/react'
import { Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { $publicOfferAcceptance } from '~/widgets/Proposal/model'
import { viewerModel } from '~/entities/viewer'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { Modal } from '~/shared/ui/Modal'

type PublicOfferModalProps = {
  isShow: boolean
  onOk: () => Promise<void>
  onClose: () => void
}

export function PublicOfferModal({
  isShow,
  onOk,
  onClose,
}: PublicOfferModalProps) {
  const publicOfferAcceptance = useStore($publicOfferAcceptance)

  const handleSignOut = () => {
    onClose()
    viewerModel.signOut()
    snackbarEnqueued({
      message: 'Обратитесь к менеджеру для уточнения информации',
      variant: 'warning',
    })
  }

  return (
    <Modal
      isShow={isShow}
      ok='Согласен'
      onOk={onOk}
      cancel='Не согласен'
      onCancel={handleSignOut}
      canBackdropClose={false}
    >
      <Typography textAlign='center' fontWeight={500} mb='16px'>
        АГЕНТСКОЕ СОГЛАШЕНИЕ
      </Typography>
      <Typography textAlign='center'>
        Пожалуйста, ознакомьтесь с условиями соглашения перед началом
        сотрудничества.
      </Typography>
      <IonButton
        href={publicOfferAcceptance?.documentFileLink as string}
        target='_blank'
        fill='clear'
      >
        Посмотреть Оферту
      </IonButton>
    </Modal>
  )
}
