import { LoadingButton } from '@mui/lab'
import { Box, Button, Unstable_Grid2 as Grid } from '@mui/material'
import { useStore } from 'effector-react'
import React, { useState } from 'react'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { VerificationFieldElement } from '~/shared/ui/form'
import { RepeatCode } from '~/shared/ui/RepeatCode'
import { myPalette } from '~/shared/ui/theme'
import { TIMER_REPEAT_LOGIN_CODE_TIME } from '../constants'
import { goToStepLoginPhone, loginFx } from '../model'
import { LoginStepTitle } from '../styled'
import { BoxCountDown } from './styled'

type StepLoginCodeProps = {
  onLogin: (code: string) => void
  onCodeRepeat: () => void
  repeatCodeStorageKey: string
}

export function StepLoginCode({
  onLogin,
  onCodeRepeat,
  repeatCodeStorageKey,
}: StepLoginCodeProps) {
  const [code, setCode] = useState<string>()
  const isLoginPending = useStore(loginFx.pending)

  const handleCodeChange = (code: string) => setCode(code)

  const handleLogin = () => {
    code
      ? onLogin(code)
      : snackbarEnqueued({ message: 'Ошибка не найден код', variant: 'danger' })

    setCode(undefined)
  }

  return (
    <Box>
      <LoginStepTitle>
        Введите код, который пришел на&nbsp;указанный номер
      </LoginStepTitle>

      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid xs={12}>
          <VerificationFieldElement
            key={`${isLoginPending}`}
            onChange={handleCodeChange}
          />
          <LoadingButton
            variant='limeFlooded'
            fullWidth
            loading={isLoginPending}
            sx={{
              marginTop: '16px',
            }}
            onClick={handleLogin}
            disabled={code?.length !== 4}
          >
            Войти
          </LoadingButton>
          <BoxCountDown>
            <RepeatCode
              onCodeRepeat={onCodeRepeat}
              time={TIMER_REPEAT_LOGIN_CODE_TIME}
              storageKey={repeatCodeStorageKey}
            />
          </BoxCountDown>
        </Grid>
        <Grid xs={12} style={{ textAlign: 'center' }}>
          <Button
            onClick={() => goToStepLoginPhone()}
            variant='text'
            fullWidth
            sx={{
              padding: 0,
              color: myPalette.mutedText.main,
              width: 'auto',
              display: 'inline-block',
            }}
          >
            Назад
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
