export function MenuIcon() {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon'>
        <path
          id='Vector (Stroke)'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M22 5.5H2V7.5H22V5.5ZM16 11.5H2V13.5H16V11.5ZM22 17.5H2V19.5H22V17.5Z'
          fill='white'
        />
      </g>
    </svg>
  )
}
