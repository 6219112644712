import { Typography } from '@mui/material'
import { useGate, useStore, useStoreMap } from 'effector-react'
import { useRef } from 'react'
import { ProgressBlock } from '~/shared/ui/Loaders'
import { $formMeta, $formMetaPending, Gate } from './mode'
import { Wrapper } from './styled'

type TinkoffPayProps = {
  amount: number
}

export function TinkoffPay({ amount }: TinkoffPayProps) {
  useGate(Gate)
  const widgetRef = useRef<HTMLElement>()
  const isFormMetaLoading = useStore($formMetaPending)
  const formMeta = useStoreMap($formMeta, (formMeta) =>
    formMeta?.getAttributes(),
  )

  const form = `
    <form name="payform-tinkoff" onsubmit="pay(this); return false;">
      <input type="hidden" name="terminalkey" value=${formMeta?.terminalId}>
      <input type="hidden" name="frame" value="false">
      <input type="hidden" name="language" value="ru">
      <input type="hidden" placeholder="Сумма заказа" name="amount" value=${amount} required>
      <input class="payform-tinkoff-row" type="hidden" name="DATA" value="Number=${formMeta?.customerId}">
      <input type="submit" value="Подтвердить" >
    </form>
  `
  if (isFormMetaLoading) {
    return <ProgressBlock height={52} />
  }

  if (!isFormMetaLoading && !formMeta) {
    return (
      <Typography fontSize='16px' textAlign='center' color='error'>
        Ошибка получения данных. Невозможно выполнить платеж!
      </Typography>
    )
  }

  return <Wrapper ref={widgetRef} dangerouslySetInnerHTML={{ __html: form }} />
}
