import WarningAmberSharpIcon from '@mui/icons-material/WarningAmberSharp'
import { Box, Typography, Stack, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { myPalette } from '~/shared/ui/theme/myPalette'

type Props = {
  message?: string
  fullScreen?: boolean
}

export function NotFound({
  message = 'Страница не найдена',
  fullScreen = true,
}: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: fullScreen ? '100vh' : 'none',
        backgroundColor: myPalette.aliceBlue.main,
        p: 1,
      }}
    >
      <Stack alignItems='center' spacing={3}>
        <WarningAmberSharpIcon color='warning' sx={{ fontSize: 60 }} />
        <Typography variant='body1' fontSize={20}>
          {message}
        </Typography>
        <Button component={Link} to='/' sx={{ fontSize: '16px' }}>
          На главную
        </Button>
      </Stack>
    </Box>
  )
}
