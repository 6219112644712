import * as z from 'zod'
import { MoveFundsType } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const schema = z.object({
  amount: z.number(),
  comment: z.string(),
  moveFundsType: z.nativeEnum(MoveFundsType),
  appliedAt: dateSchema,
  createdAt: dateSchema,
})

export type BalanceHistoryAttributes = z.infer<typeof schema>

export class BalanceHistory extends ApiModel<
  typeof schema,
  BalanceHistoryAttributes
> {
  static jsonApiType = 'balance-histories'

  static schema = schema

  getAmount(): number {
    return this.getAttribute('amount')
  }

  getAppliedAt(): string {
    return this.getAttribute('appliedAt')
  }

  getCreatedAt(): string {
    return this.getAttribute('createdAt')
  }

  getComment(): string {
    return this.getAttribute('comment')
  }

  getMoveFundsType(): MoveFundsType {
    return this.getAttribute('moveFundsType')
  }
}
