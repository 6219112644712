import { createDomain } from 'effector'
import { createGate } from 'effector-react'

export const Gate = createGate()

const domain = createDomain('entities.withdrawal.drawer')

export const openWithdrawalDrawer = domain.createEvent()
export const closeWithdrawalDrawer = domain.createEvent()

export const $isWithdrawalDrawerOpen = domain
  .createStore<boolean>(false)
  .on(openWithdrawalDrawer, () => true)
  .on(closeWithdrawalDrawer, () => false)
