import { IonContent, IonModal as IonicIonModal } from '@ionic/react'
import { styled, Box } from '@mui/material'
import { BODY_MAX_WIDTH, myPalette } from '../theme'

export const IonModal = styled(IonicIonModal)<{
  isAutoHeight?: boolean
  isOpen: boolean
}>(({ isAutoHeight, isOpen }) => ({
  '--max-width': BODY_MAX_WIDTH,
  '--border-radius': '20px',
  ...(isAutoHeight ? { '--height': 'auto !important' } : {}),

  '&::part(backdrop)': {
    ...(isOpen
      ? { background: 'rgba(0, 0, 0, 0.87)' }
      : { background: 'transparent' }),
  },

  '&::part(handle)': {
    width: '110px',
    background: myPalette.dark.drawerTopLine,
    top: '12px',
    height: '4px',

    '&:before': {
      width: '110px',
      height: '46px',
    },
  },
}))

export const IonContentCustom = styled(IonContent)`
  --background: ${myPalette.aliceBlue.main};

  &::part(scroll) {
    overscroll-behavior: none;
  }
`

export const ContentWrapper = styled(Box)`
  padding: 28px 16px 24px;
  background: ${myPalette.aliceBlue.main};
`
