import { Box, styled, IconButton as MuiIconButton } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: ${myPalette.aliceBlue.main};
`

export const IconButton = styled(MuiIconButton)`
  position: relative;
  z-index: 1;
`

export const MainBlock = styled(Box)`
  text-align: center;
  width: 100%;

  .fade-back-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  .fade-back-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-back-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-back-exit-active {
    opacity: 0;
    transform: translateX(100%);
  }
  .fade-back-enter-active,
  .fade-back-exit-active {
    transition: opacity 300ms, transform 300ms;
  }

  .fade-next-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  .fade-next-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-next-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-next-exit-active {
    opacity: 0;
    transform: translateX(-100%);
  }
  .fade-next-enter-active,
  .fade-next-exit-active {
    transition: opacity 300ms, transform 300ms;
  }
`
