export function PlusIcon() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.2751 11.9999H4.71875M12.4969 4.22168V19.7781'
        stroke='#021542'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
