import { useGate, useStore } from 'effector-react'
import { useState } from 'react'
import { Penalty } from '~/shared/api'
import { ImageSliderModal } from '~/shared/ui/ImageSliderModal'
import { ProgressBlock } from '~/shared/ui/Loaders'
import { useModal } from '~/shared/ui/Modal'
import { $photos, fetchImageFx, Gate } from './model'
import { Field, ImgsWrapper, ImgWrapper, Title } from './styled'

type ContentProps = {
  penalty: Penalty
}

export function Content({ penalty }: ContentProps) {
  useGate(Gate, penalty.getFiles() || [])
  const [selectedPhotoId, setSelectedPhotoId] = useState<UniqueId>()
  const { isModalShow, openModal, closeModal } = useModal()

  const photos = useStore($photos) || []
  const isPhotosLoading = useStore(fetchImageFx.pending)

  const handleSliderModalShow = (photoId: UniqueId) => {
    setSelectedPhotoId(photoId)
    openModal()
  }

  return (
    <>
      {penalty && (
        <>
          {isPhotosLoading && !photos?.length && <ProgressBlock height={175} />}

          {!isPhotosLoading && !photos?.length && <Field>Фотографий нет</Field>}

          {!!photos?.length && (
            <ImgsWrapper>
              {photos.map((photo) => (
                <ImgWrapper
                  key={photo.id}
                  onClick={() => handleSliderModalShow(photo.id)}
                >
                  <img
                    src={photo.src}
                    width='100%'
                    height='auto'
                    alt='Фотография штрафа'
                  />
                </ImgWrapper>
              ))}
            </ImgsWrapper>
          )}

          <Title>
            {penalty.getPenaltyLawArticleNumber()} -{' '}
            {penalty.getPenaltyLawArticleDescription()}
          </Title>
          <Field>{penalty.getProtocolNumber()}</Field>
          <Field>{penalty.getCarVin()}</Field>
          <Field>{penalty.getOdpsName()}</Field>
        </>
      )}

      <ImageSliderModal
        images={photos}
        isShow={isModalShow}
        onClose={closeModal}
        visibleImageId={selectedPhotoId}
      />
    </>
  )
}
