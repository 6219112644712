import { Box, styled } from '@mui/material'

export const TitleWrapper = styled(Box)`
  margin: 24px 0;
  text-align: center;
`

export const ActionsWrapper = styled(Box)`
  display: flex;
  justify-content: space-around;
`
