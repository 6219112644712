import { ReactNode } from 'react'
import { Wrapper } from './styled'

type PayCardWrapperProps = {
  children: ReactNode
  onClick?: () => void
}

export function PayCardWrapper({ children, onClick }: PayCardWrapperProps) {
  return (
    <Wrapper direction='row' spacing={2} onClick={onClick}>
      {children}
    </Wrapper>
  )
}
