import { Box, styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Wrapper = styled(Box)`
  color: ${myPalette.mutedText.main};
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 11px;
  align-items: center;
`
