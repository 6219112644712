import { Box, styled, Typography } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Card = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 16px;
`

export const TitleBlock = styled(Box)`
  margin-right: 16px;
  width: 100%;
`

export const TitleWrapper = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: ${myPalette.midnightBlue.main};
  margin-bottom: 4px;
`

export const Date = styled(Typography)`
  font-size: 14px;
  color: ${myPalette.mutedText.main};
`

export const Sum = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  color: ${myPalette.midnightBlue.main};
  width: 100%;
  text-align: right;
`
