export const additionalLinks = [
  {
    title: 'Политика конфиденциальности',
    to: 'https://apark-pro.obs.ru-moscow-1.hc.sbercloud.ru/acceptable_documents/privacy.pdf',
  },
  {
    title: 'Условия пользования',
    to: 'https://apark-pro.obs.ru-moscow-1.hc.sbercloud.ru/acceptable_documents/user_agreement.pdf',
  },
  // {
  //   title: 'О приложении',
  //   to: '/3',
  // },
]
