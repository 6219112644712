import { Button, Typography } from '@mui/material'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { WrapperMap } from '../styled'

type Props = {
  isShow: boolean
  onCancel: () => void
}

export function SaintPetersburgDrawer({ isShow, onCancel }: Props) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onCancel}>
      <Typography fontWeight={600}>Адрес и контакты парков:</Typography>
      <br />
      <Typography>
        Метро Старая Деревная, ул. Планерная д.7{' '}
        <WrapperMap>
          (
          <Button
            href='https://yandex.ru/maps/2/saint-petersburg/?ll=30.233358%2C59.987063&mode=routes&rtext=~59.987231%2C30.233052&rtt=taxi&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NzQzMDA1ORJN0KDQvtGB0YHQuNGPLCDQodCw0L3QutGCLdCf0LXRgtC10YDQsdGD0YDQsywg0J_Qu9Cw0L3QtdGA0L3QsNGPINGD0LvQuNGG0LAsIDciCg1K3fFBFezyb0I%2C&utm_source=main_stripe_big&z=18'
            target='_blank'
            sx={{ m: 0, p: 0 }}
          >
            Яндекс-карты
          </Button>
          )
        </WrapperMap>
      </Typography>
      <Typography fontWeight={600}>Начальник колонны</Typography>
      <Typography>
        - СПБ СЕВЕР 1 Безрукова Надежда:{' '}
        <Button href='tel:+79637133248' sx={{ m: 0, p: 0 }}>
          +7 (963) 713-32-48
        </Button>
      </Typography>
      <Typography>
        - СПБ СЕВЕР 2 Никулина Наталья:{' '}
        <Button href='tel:+79251135604' sx={{ m: 0, p: 0 }}>
          +7 (925) 113-56-04
        </Button>
      </Typography>
      <br />

      <Typography>
        Метро Обухово, ул. Софийская 62, к6{' '}
        <WrapperMap>
          (
          <Button
            href='https://yandex.ru/maps/2/saint-petersburg/?ll=30.427637%2C59.853058&mode=routes&rtext=~59.853058%2C30.427636&rtt=taxi&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgo0OTQ5NTQ4NTg2ElHQoNC-0YHRgdC40Y8sINCh0LDQvdC60YIt0J_QtdGC0LXRgNCx0YPRgNCzLCDQodC-0YTQuNC50YHQutCw0Y8g0YPQu9C40YbQsCwgNjLQujYiCg3La_NBFYhpb0I%2C&utm_source=main_stripe_big&z=17'
            target='_blank'
            sx={{ m: 0, p: 0 }}
          >
            Яндекс-карты
          </Button>
          )
        </WrapperMap>
      </Typography>
      <Typography fontWeight={600}>Начальник колонны</Typography>
      <Typography>
        - СПБ ЮГ 1 Тхапсаев Леонид:{' '}
        <Button href='tel:+79251135859' sx={{ m: 0, p: 0 }}>
          +7 (925) 113-58-59
        </Button>
      </Typography>
      <Typography>
        - СПБ ЮГ 2 Акрамов Баходур:{' '}
        <Button href='tel:+79250106981' sx={{ m: 0, p: 0 }}>
          +7 (925) 010-69-81
        </Button>
      </Typography>
      <br />

      <Typography fontWeight={600}>Сервис</Typography>
      <Typography>
        <Button href='tel:+78123132369' sx={{ m: 0, p: 0 }}>
          +7 (812) 313-23-69
        </Button>
      </Typography>
      <Typography>
        <Button href='tel:+78124301111' sx={{ m: 0, p: 0 }}>
          +7 (812) 430-11-11
        </Button>
      </Typography>
    </DrawerComponent>
  )
}
