import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material'

export const Button = styled(LoadingButton)`
  font-weight: 700;
  justify-content: flex-start;
  padding: 14px 18px !important;
  box-shadow: 0 0 5px 0 #0015461a;
  border: none;
  border-radius: 12px;

  .MuiButton-startIcon {
    margin-right: 16px;
  }
`
