import * as z from 'zod'
import { ApiModel } from './core'

const schema = z.object({})

export class Media extends ApiModel<typeof schema> {
  static jsonApiType = 'media'

  static schema = schema

  static async show(uuid: string): Promise<Blob> {
    const url = `${Media.getJsonApiUrl()}/show/${uuid}`
    const client = Media.httpClient.getImplementingClient()

    const response = await client.get(url, {
      responseType: 'blob',
    })

    return response?.data
  }
}
