import { setDefaultOptions } from 'date-fns'
import locale from 'date-fns/locale/ru'

setDefaultOptions({
  locale,
})

export const apiDateFormat = 'yyyy-MM-dd'
export const uiDateFormat = 'dd.MM.yyyy'
export const uiDateTimeFormat = 'dd.MM.yyyy HH:mm'
export const uiTimeFormat = 'HH:mm'
