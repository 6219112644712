import {
  createBrowserHistory,
  createMemoryHistory,
  To,
} from '@remix-run/router'
import { createEffect } from 'effector'

export let history = createBrowserHistory({ v5Compat: true })
/*
 * TODO: убрать обработку тестового окружения из этого файла
 * */
if (process.env.NODE_ENV === 'test') {
  history = createMemoryHistory()
}

export const pushFx = createEffect<To, void>({
  async handler(to) {
    history.push(to)
  },
})

export const replaceFx = createEffect<To, void>({
  async handler(to) {
    history.replace(to)
  },
})

export const backFx = createEffect({
  async handler() {
    history.go(-1)
  },
})
