import { Box, styled, Typography } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Card = styled(Box)`
  height: 68px;
  background: ${myPalette.white.main};
  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(0, 21, 70, 0.1);
  padding: 16px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
`

export const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: ${myPalette.midnightBlue.main};
  cursor: pointer;
`
