import { Box, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { PayForm, payModel, confirmPayModel } from '~/entities/Balance'
import { PayCommission, PayCommissionAttributes } from '~/shared/api'
import { CURRENCY } from '~/shared/config/constants'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { Commission } from './ui/Commission'

type PayDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function PayDrawer({ isShow, onClose }: PayDrawerProps) {
  const [amount, setAmount] = useState<number>()

  const gridKey = useMemo(() => ['getPayCommission', amount], [amount])

  const [debounceGridKey] = useDebounce(gridKey, 400)

  const {
    data: resCommission,
    isFetching,
    remove,
  } = useQuery<PayCommissionAttributes>(
    debounceGridKey,
    () => PayCommission.commission(amount as number),
    {
      enabled: Boolean(amount && amount >= 50),
      retry: false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  )

  const handleDrawerClose = () => {
    setAmount(undefined)
    remove()
    onClose()
  }

  useEffect(() => {
    payModel.setPayCommission(resCommission && amount ? resCommission : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resCommission])

  return (
    <DrawerComponent isShow={isShow} onCancel={handleDrawerClose}>
      <Box sx={{ textAlign: 'center', marginBottom: '44px' }}>
        <Typography fontSize={18} fontWeight={600}>
          Пополнение баланса
        </Typography>
        <Typography fontSize={14}>
          Минимальная сума пополнение 50 {CURRENCY}
        </Typography>
      </Box>

      <PayForm
        isSubmitting={isFetching}
        onSuccess={confirmPayModel.openConfirmPayDrawer}
        onSumChange={setAmount}
        disable={!resCommission}
        additionalContent={<Commission isLoading={isFetching} />}
      />
    </DrawerComponent>
  )
}
