import { Box, styled } from '@mui/material'
import { ImageListItem as MuiImageListItem } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const ImageListItem = styled(MuiImageListItem)`
  position: relative;
  cursor: pointer;
`

export const Title = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px;
  font-size: 16px;
  color: ${myPalette.midnightBlue.main};
  line-height: 18px;
  font-weight: 500;
`
