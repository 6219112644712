import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const LoginStepTitle = styled(Typography)`
  color: ${myPalette.mutedText.main};
  font-size: 16px;
  margin: 9px 0 37px;
  text-align: center;
`
