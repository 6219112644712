import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import { Logo } from '~/shared/ui/Logo'
import { MainBox, ContentWrapper, PageHeader } from './styled'

type Props = {
  children: ReactNode
  title: string
}

export function LogoContainer({ children, title }: Props) {
  return (
    <MainBox>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageHeader>
        <Logo logoWidth={132} textFontSize={0} />
      </PageHeader>
      <ContentWrapper>{children}</ContentWrapper>
    </MainBox>
  )
}
