import { menuController } from '@ionic/core/components'
import { IonButton, IonHeader, IonPage } from '@ionic/react'
import { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import { MenuIcon } from '~/shared/ui/icons'
import { myPalette } from '~/shared/ui/theme'
import { ButtonBack } from '../ButtonBack'
import {
  TopContent,
  Content,
  WrapperHeader,
  WrapperCap,
  IonToolbar,
  IonContent,
  Cap,
  ContentWrapper,
} from './styled'

type Props = {
  children: ReactNode
  title: string
  canMenu?: boolean
  canBack?: boolean
  onBack?: () => void
  topContent: ReactNode
  extraRight?: ReactNode
}

export function PageContainer({
  title,
  canMenu,
  children,
  canBack,
  onBack,
  topContent,
  extraRight,
}: Props) {
  async function openFirstMenu() {
    await menuController.open('main-menu')
  }

  return (
    <IonPage
      id='page-container'
      style={{
        background: myPalette.midnightBlue.main,
      }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <IonHeader>
        <IonToolbar>
          <WrapperHeader>
            {canMenu && (
              <IonButton
                fill='clear'
                onClick={openFirstMenu}
                data-testid='home-menu-button'
              >
                <MenuIcon />
              </IonButton>
            )}

            {canBack && <ButtonBack onBack={onBack} sx={{ mr: 1 }} />}
            {extraRight}
          </WrapperHeader>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <ContentWrapper>
          <TopContent>{topContent}</TopContent>
          <WrapperCap>
            <Cap />
          </WrapperCap>
          <Content>{children}</Content>
        </ContentWrapper>
      </IonContent>
    </IonPage>
  )
}
