import { createDomain } from 'effector'
import { createGate } from 'effector-react'

export const Gate = createGate()

const domain = createDomain('entities.pay.drawer')

export const openPayDrawer = domain.createEvent()
export const closePayDrawer = domain.createEvent()

export const $isPayDrawerOpen = domain
  .createStore<boolean>(false)
  .on(openPayDrawer, () => true)
  .on(closePayDrawer, () => false)
