import {
  IonContent as IonicIonContent,
  IonToolbar as IonicIonToolbar,
} from '@ionic/react'
import { styled, Box } from '@mui/material'
import { HEADER_HEIGHT } from '~/shared/ui/theme'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const IonToolbar = styled(IonicIonToolbar)`
  --background: ${myPalette.midnightBlue.main};
  --border-width: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-bottom: 0;
  --padding-top: 0px;
  --box-shadow: none;
`

export const WrapperHeader = styled(Box)`
  background: ${myPalette.midnightBlue.main};
  min-height: ${HEADER_HEIGHT};
  max-height: ${HEADER_HEIGHT};
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TopContent = styled(Box)`
  background: ${myPalette.midnightBlue.main};
  padding: 0 16px 52px;
`

export const WrapperCap = styled(Box)`
  height: 22px;
  background: ${myPalette.midnightBlue.main};
  position: sticky;
  top: 0;
  z-index: 1;
  margin-top: -21px;
`

export const Cap = styled(Box)`
  height: 24px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: ${myPalette.aliceBlue.main};
`

export const IonContent = styled(IonicIonContent)`
  --background: ${myPalette.midnightBlue.main};
  --overscroll-behavior: none;
  --webkit-overscroll-behavior: none;
`
export const ContentWrapper = styled(Box)`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`

export const Content = styled(Box)`
  background: ${myPalette.aliceBlue.main};
  padding: 6px 16px 16px;
  flex: 1;
`
