import * as z from 'zod'
import { sanitizePriceNumber } from '~/shared/lib/utils'

export const paySchema = z.object({
  amount: z
    .string()
    .or(z.number())
    .superRefine((value, ctx) => {
      const sanitized = sanitizePriceNumber(value)
      if (!sanitized) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Обязательное поле',
        })
      }
      if (sanitized < 50) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Минимальная сумма пополнения 50 рублей',
        })
      }
    })
    .transform(sanitizePriceNumber),
})
export type TypeFormSchema = z.infer<typeof paySchema>
export type FormValues = TypeFormSchema
