export function BusinessUserIcon() {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='18' fill='#021542' />
      <path
        d='M21 12C21 13.6569 19.6569 15 18 15C16.3431 15 15 13.6569 15 12C15 10.3431 16.3431 9 18 9C19.6569 9 21 10.3431 21 12Z'
        stroke='#E4ECFF'
        strokeWidth='1.5'
      />
      <path
        d='M15 18C13.2066 18.2775 12 19.0097 12 20.8981C12 23.7158 14.6863 26 18 26C21.3137 26 24 23.7158 24 20.8981C24 19.0097 22.7934 18.2775 21 18'
        stroke='#E4ECFF'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
