import { Button, Typography } from '@mui/material'
import { DrawerComponent } from '~/shared/ui/Drawer'

type DocumentsDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function DocumentsDrawer({ isShow, onClose }: DocumentsDrawerProps) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onClose} isAutoHeight={false}>
      <Typography>
        При выдаче автомобиля вы получаете пакет документов, который всегда
        должен быть с вами во время эксплуатации, даже если вы совершаете
        поездку в личных целях.
      </Typography>
      <br />
      <Typography fontWeight={600}>Список документов:</Typography>
      <br />
      <Typography>1. Свидетельство транспортного средства (СТС)</Typography>
      <br />
      <Typography>2. Лицензия</Typography>
      <br />
      <Typography>3. Страховой полис ОСАГО</Typography>
      <br />
      <Typography>
        4. Диагностическая карта (ДК) - подтверждает исправность и безопасность
        автомобиля
      </Typography>
      <br />
      <Typography>5. Путевой лист (действителен не более 12 часов)</Typography>
      <br />
      <Typography>
        6. Бланк европротокола (можно самостоятельно скачать на сайте{' '}
        <Button
          href='https://autoins.ru/evropeyskiy-protokol/uproshchennoe-oformlenie-dtp/mob_app/'
          sx={{ m: 0, p: 0 }}
        >
          Официальный сайт РСА
        </Button>
        )
      </Typography>
      <br />
      <Typography>7. Водительское удостоверение</Typography>
      <br />
      <Typography>
        Также обратите внимание, на панели авто должна быть размещена тарифная
        сетка.
      </Typography>
    </DrawerComponent>
  )
}
