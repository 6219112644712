import { Box, styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Wrapper = styled(Box)`
  width: 100%;
  .vi__character {
    background-color: ${myPalette.white.main};
    border-radius: 6px;
    border: 1px solid ${myPalette.dark.inputBorder};
    height: 56px;
  }
  .vi__container {
    width: 100%;
  }
  .vi__wrapper {
    width: 100%;
    height: 56px;
  }
  .MuiInputBase-root {
    display: none;
  }
  .MuiFormHelperText-root {
    margin: 8px 0;
  }
`
