import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { useForm } from 'react-hook-form'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { sanitizePhoneNumber } from '~/shared/lib/utils'
import { useStorageRepeatCode } from '~/shared/ui/RepeatCode'
import {
  LoginStepsEnum,
  TIMER_REPEAT_LOGIN_CODE,
  TIMER_REPEAT_LOGIN_CODE_TIME,
} from './constants'
import {
  $loginStep,
  Gate,
  goToStepLoginCode,
  loginFx,
  requestLoginCodeFx,
} from './model'
import { StepLoginCode } from './StepLoginCode'
import { StepLoginPhone, stepLoginPhoneModel } from './StepLoginPhone'

export function Login() {
  useGate(Gate)

  const form = useForm<stepLoginPhoneModel.LoginPhoneFormValues>({
    resolver: zodResolver(stepLoginPhoneModel.loginPhoneSchema),
  })
  const phone = form.watch('phone')

  const repeatCodeStorageKey = `${TIMER_REPEAT_LOGIN_CODE}-${phone}`

  const loginStep = useStore($loginStep)
  const { runTimer, getIsTimerWorking } =
    useStorageRepeatCode(repeatCodeStorageKey)

  const sanitizePhone = sanitizePhoneNumber(phone)

  const handleLogin = async (code: string) => {
    await loginFx({ phone: sanitizePhone, code })
  }

  const warningRequestCod = () => {
    snackbarEnqueued({
      message:
        'Запросите код повторно после того как пройдет блокировка получения кода',
      variant: 'warning',
    })
  }

  const requestLoginCode = () => {
    return requestLoginCodeFx(sanitizePhone).then(() =>
      runTimer(TIMER_REPEAT_LOGIN_CODE_TIME),
    )
  }

  const handleCodeRequest = async () => {
    getIsTimerWorking() ? warningRequestCod() : await requestLoginCode()

    goToStepLoginCode()
  }

  return (
    <Box>
      {loginStep === LoginStepsEnum.LOGIN_PHONE && (
        <StepLoginPhone form={form} onSuccess={handleCodeRequest} />
      )}
      {loginStep === LoginStepsEnum.LOGIN_CODE && (
        <StepLoginCode
          onLogin={handleLogin}
          onCodeRepeat={handleCodeRequest}
          repeatCodeStorageKey={repeatCodeStorageKey}
        />
      )}
    </Box>
  )
}
