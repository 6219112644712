import { Typography, Box } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import React from 'react'

type Props = {
  logoWidth?: number
  textFontSize?: number
  sx?: SxProps<Theme>
}

export function Logo({ logoWidth = 132, textFontSize = 14, sx }: Props) {
  return (
    <Box sx={sx}>
      {!!textFontSize && (
        <Typography fontSize={textFontSize} color={'#fc9235'}>
          Приложение Водителя
        </Typography>
      )}
      <Box component='img' src='/images/logo.svg' width={logoWidth} mx='auto' />
    </Box>
  )
}
