import { Skeleton } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { useEffect } from 'react'
import { profileModel } from '~/widgets/Profile'
import { viewerModel } from '~/entities/viewer'
import { formatDateForUI } from '~/shared/lib/date'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { Modal } from '~/shared/ui/Modal'
import { Field, Label, Title } from './styled'

type DriverPersonalDataModalProps = {
  isShow: boolean
  onOk: () => Promise<void>
}

export function DriverPersonalDataModal({
  isShow,
  onOk,
}: DriverPersonalDataModalProps) {
  const driverProfilePending = useStore(profileModel.getDriverProfileFx.pending)

  const fullName = useStoreMap(profileModel.$driverProfile, (profile) =>
    profile?.getFullName(),
  )
  const passportNumber = useStoreMap(profileModel.$driverProfile, (profile) =>
    profile?.getPassportNumber(),
  )
  const passportIssueDate = useStoreMap(
    profileModel.$driverProfile,
    (profile) => profile?.getPassportIssueDate(),
  )

  useEffect(() => {
    isShow && profileModel.getDriverProfile()
  }, [isShow])

  const handleSignOut = () => {
    viewerModel.signOut()
    snackbarEnqueued({
      message:
        'Обратитесь к менеджеру для изменения некорректных данных и повторите вход',
      variant: 'warning',
    })
  }

  return (
    <Modal
      isShow={isShow}
      ok='Все верно'
      cancel='Данные не верны'
      onOk={onOk}
      onCancel={handleSignOut}
      canBackdropClose={false}
    >
      <Title>Подтвердите ваши персональные данные</Title>

      <Label>Ваше имя</Label>
      {driverProfilePending ? (
        <Skeleton variant='rounded' height={24} sx={{ marginBottom: '8px' }} />
      ) : (
        <Field>{fullName}</Field>
      )}

      <Label>Серия и номер паспорта</Label>
      {driverProfilePending ? (
        <Skeleton variant='rounded' height={24} sx={{ marginBottom: '8px' }} />
      ) : (
        <Field>{passportNumber}</Field>
      )}

      <Label>Дата выдачи</Label>
      {driverProfilePending ? (
        <Skeleton variant='rounded' height={24} sx={{ marginBottom: '8px' }} />
      ) : (
        <Field>
          {passportIssueDate ? formatDateForUI(passportIssueDate) : '-'}
        </Field>
      )}
    </Modal>
  )
}
