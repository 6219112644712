export function MenuTouchIDIcon() {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        y='36'
        width='36'
        height='36'
        rx='18'
        transform='rotate(-90 0 36)'
        fill='#021542'
      />
      <path
        d='M11 16.5C11 15.3092 11.2973 14.1879 11.8218 13.2063M25 16.0625C25 15.0387 24.7656 14.0698 24.3475 13.2063C23.9718 12.4304 23.4479 11.7397 22.8125 11.171C21.6515 10.1319 20.1183 9.5 18.4375 9.5H18C17.1413 9.5 16.3188 9.65461 15.5586 9.9375'
        stroke='#E4ECFF'
        strokeWidth='1.2'
        strokeLinecap='round'
      />
      <path
        d='M25 19.7635L25 20.1892C25 21.3478 24.7027 22.4388 24.1782 23.3939M11 19.125L11 20.6149C11 21.6109 11.2344 22.5538 11.6525 23.3939C12.0282 24.1488 12.5521 24.8208 13.1875 25.3741C14.3485 26.3852 15.8817 27 17.5625 27L18 27C18.8587 27 19.6812 26.8496 20.4414 26.5743'
        stroke='#E4ECFF'
        strokeWidth='1.2'
        strokeLinecap='round'
      />
      <path
        d='M12.75 16.5C12.75 13.6005 15.1005 11.25 18 11.25M23.25 20.4375V16.5C23.25 15.5437 22.9943 14.6472 22.5476 13.875M12.75 20.875V19.125'
        stroke='#E4ECFF'
        strokeWidth='1.2'
        strokeLinecap='round'
      />
      <path
        d='M21.5 23.5V19.5078M14.5 24.375V22.1875M21.5 17.2109V16.5C21.5 14.567 19.933 13 18 13V13C16.067 13 14.5 14.567 14.5 16.5V19.125'
        stroke='#E4ECFF'
        strokeWidth='1.2'
        strokeLinecap='round'
      />
      <path
        d='M19.75 24.375V16.5C19.75 15.5335 18.9665 14.75 18 14.75V14.75C17.0335 14.75 16.25 15.5335 16.25 16.5V17.75M16.25 25.25V20.75'
        stroke='#E4ECFF'
        strokeWidth='1.2'
        strokeLinecap='round'
      />
      <path
        d='M18 16.5V23.5'
        stroke='#E4ECFF'
        strokeWidth='1.2'
        strokeLinecap='round'
      />
    </svg>
  )
}
