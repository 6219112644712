import { useCallback } from 'react'
import { storageGet, storageRemove, storageSet } from '~/shared/lib/storage'

export const useStorageRepeatCode = (storageKey: string) => {
  const timeStorageGet = useCallback(
    () => storageGet<number>(storageKey),
    [storageKey],
  )

  const timeStorageSet = useCallback(
    (time: number) => storageSet(storageKey, Date.now() + time),
    [storageKey],
  )

  const timeStorageRemove = useCallback(
    () => storageRemove(storageKey),
    [storageKey],
  )

  const runTimer = useCallback(
    (time: number) => {
      timeStorageSet(time)
    },
    [timeStorageSet],
  )

  const getIsTimerWorking = useCallback(() => {
    const storageTime = timeStorageGet()
    return Boolean(storageTime && storageTime - Date.now() > 0)
  }, [timeStorageGet])

  return {
    timeStorageRemove,
    timeStorageGet,
    timeStorageSet,
    runTimer,
    getIsTimerWorking,
  }
}
