// import { penaltiesModel } from '~/widgets/Fines'
// import { notificationsModel } from '~/widgets/Notifications'
import { penaltiesModel } from 'src/widgets/Penalties'
import { profileModel } from '~/widgets/Profile'
import { supportModel } from '~/widgets/Support'
import { viewerModel } from '~/entities/viewer'
import {
  MenuTouchIDIcon,
  // MenuNotificationsIcon,
  MenuCameraIcon,
  // MenuFireIcon,
  MenuQuestionIcon,
  // MenuPresentIcon,
  MenuLogoutIcon,
  MenuAppRegistrationIcon,
} from '~/shared/ui/icons'

import { MenuType } from '../type'

export const useMenu = (): MenuType[] => {
  return [
    {
      title: 'Личные данные',
      icon: <MenuTouchIDIcon />,
      fn: profileModel.openProfileDrawer,
    },
    // {
    //   title: 'Уведомления',
    //   icon: <MenuNotificationsIcon />,
    //   fn: notificationsModel.openNotificationsDrawer,
    //   notification: true,
    // },
    {
      title: 'Штрафы',
      icon: <MenuCameraIcon />,
      fn: penaltiesModel.openPenaltiesDrawer,
      notification: true,
    },
    // {
    //   title: 'Программа лояльности',
    //   icon: <MenuPresentIcon />,
    //   to: '/',
    //   disabled: true,
    // },
    // {
    //   title: 'Частые вопросы',
    //   icon: <MenuFireIcon />,
    //   to: '/',
    //   disabled: true,
    // },
    {
      title: 'Поддержка',
      icon: <MenuQuestionIcon />,
      fn: supportModel.openSupportDrawer,
    },
    {
      title: 'Запись на осмотр',
      icon: <MenuAppRegistrationIcon />,
      href: 'https://инфо369.рф',
    },
    {
      title: 'Выйти',
      icon: <MenuLogoutIcon />,
      fn: viewerModel.logoutFx,
    },
  ]
}
