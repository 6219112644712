import { Components } from '@ionic/core'
import { StyleReactProps } from '@ionic/react/dist/types/components/react-component-lib/interfaces'
import { BoxProps, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ReactNode } from 'react'
import { ContentWrapper, IonContentCustom, IonModal } from './styled'

type DrawerProps = {
  children: ReactNode
  isShow: boolean
  onCancel?: () => void
  isAutoHeight?: boolean
  contentSx?: SxProps<Theme>
  ionContent?: Partial<Components.IonContent & StyleReactProps['style']>
  autoHeightContent?: Partial<BoxProps>
} & Partial<Components.IonModal>

export function DrawerComponent({
  isShow,
  children,
  onCancel,
  isAutoHeight = true,
  contentSx,
  ionContent,
  autoHeightContent,
  ...props
}: DrawerProps) {
  return (
    <IonModal
      isOpen={isShow}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      keepContentsMounted={false}
      onWillDismiss={onCancel}
      isAutoHeight={isAutoHeight}
      {...props}
    >
      {isAutoHeight ? (
        <ContentWrapper {...autoHeightContent} sx={contentSx}>
          {children}
        </ContentWrapper>
      ) : (
        <IonContentCustom fullscreen {...ionContent}>
          <ContentWrapper sx={contentSx}>{children}</ContentWrapper>
        </IonContentCustom>
      )}
    </IonModal>
  )
}
