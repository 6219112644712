/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import {
  Control,
  Controller,
  ControllerProps,
  FieldError,
  Path,
  FieldValues,
} from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

export type TextFieldPriceSchema = Omit<FieldValues, 'type'>

export type NumericFormatElementProps<T extends TextFieldPriceSchema> = {
  name: Path<T>
  required?: boolean
  parseError?: (error: FieldError) => string
  validation?: ControllerProps['rules']
  control?: Control<T>
  inputProps?: TextFieldProps
  helperText?: TextFieldProps['helperText']
}

export function NumericFormatElement<
  TFieldValues extends TextFieldPriceSchema,
>({
  parseError,
  name,
  required,
  validation = {},
  inputProps,
  control,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
NumericFormatElementProps<TFieldValues, any, any>): JSX.Element {
  if (required && !validation.required) {
    validation.required = 'Это обязательное поле'
  }

  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => (
        <NumericFormat
          name={name}
          required={required}
          error={invalid}
          onChange={onChange}
          value={value}
          customInput={TextField}
          thousandSeparator=' '
          allowNegative={false}
          decimalScale={2}
          decimalSeparator={','}
          {...inputProps}
          {...rest}
          helperText={
            error
              ? typeof parseError === 'function'
                ? parseError(error)
                : error.message
              : inputProps?.helperText || rest.helperText
          }
        />
      )}
    />
  )
}
