import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button, Typography } from '@mui/material'
import { DrawerComponent, useDrawer } from '~/shared/ui/Drawer'
import { Card, Title, TitleWrapper } from './styled'

type RoadAccidentDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function RoadAccidentDrawer({
  isShow,
  onClose,
}: RoadAccidentDrawerProps) {
  const {
    openDrawer: participantInAccidentOpen,
    closeDrawer: participantInAccidentClose,
    isDrawerShow: isParticipantInAccidentShow,
  } = useDrawer()
  const {
    openDrawer: europeanProtocolOpen,
    closeDrawer: europeanProtocolClose,
    isDrawerShow: isEuropeanProtocolShow,
  } = useDrawer()
  const {
    openDrawer: victimsOpen,
    closeDrawer: victimsClose,
    isDrawerShow: isVictimsShow,
  } = useDrawer()
  const {
    openDrawer: howManyParticipantsOpen,
    closeDrawer: howManyParticipantsClose,
    isDrawerShow: isHowManyParticipantsShow,
  } = useDrawer()
  const {
    openDrawer: osagoOpen,
    closeDrawer: osagoClose,
    isDrawerShow: isOsagoShow,
  } = useDrawer()
  const {
    openDrawer: howManyElementsDamagedOpen,
    closeDrawer: howManyElementsDamagedClose,
    isDrawerShow: isHowManyElementsDamagedShow,
  } = useDrawer()

  return (
    <>
      <DrawerComponent isShow={isShow} onCancel={onClose}>
        <Card onClick={participantInAccidentOpen}>
          <TitleWrapper>
            <Title sx={{ ml: '12px' }}>
              Вы стали участником ДТП, что делать?
            </Title>
          </TitleWrapper>
          <ChevronRightIcon />
        </Card>
        <Card onClick={europeanProtocolOpen}>
          <TitleWrapper>
            <Title sx={{ ml: '12px' }}>Европротокол</Title>
          </TitleWrapper>
          <ChevronRightIcon />
        </Card>
      </DrawerComponent>

      <DrawerComponent
        isShow={isParticipantInAccidentShow}
        onCancel={participantInAccidentClose}
      >
        <Card onClick={victimsOpen}>
          <TitleWrapper>
            <Title sx={{ ml: '12px' }}>Пострадавшие?</Title>
          </TitleWrapper>
          <ChevronRightIcon />
        </Card>
        <Card onClick={howManyParticipantsOpen}>
          <TitleWrapper>
            <Title sx={{ ml: '12px' }}>Сколько участников ДТП?</Title>
          </TitleWrapper>
          <ChevronRightIcon />
        </Card>
        <Card onClick={osagoOpen}>
          <TitleWrapper>
            <Title sx={{ ml: '12px' }}>
              ОСАГО есть у каждого из участников ДТП?
            </Title>
          </TitleWrapper>
          <ChevronRightIcon />
        </Card>
        <Card onClick={howManyElementsDamagedOpen}>
          <TitleWrapper>
            <Title sx={{ ml: '12px' }}>
              Сколько элементов повреждено на автомобиле парка?
            </Title>
          </TitleWrapper>
          <ChevronRightIcon />
        </Card>
      </DrawerComponent>

      <DrawerComponent isShow={isVictimsShow} onCancel={victimsClose}>
        <Typography fontWeight={600}>Пострадавшие?</Typography>
        <br />
        <Typography>
          <strong>Есть пострадавшие</strong> {'->'} Вызвать скорую помощь и
          ГИБДД
        </Typography>
        <br />
        <Typography>
          <strong>Пострадавших нет</strong> {'->'} Сообщить о происшествии в
          Службу поддержки водителей с предоставлением фото и видео повреждений
        </Typography>
      </DrawerComponent>

      <DrawerComponent
        isShow={isHowManyParticipantsShow}
        onCancel={howManyParticipantsClose}
      >
        <Typography fontWeight={600}>Сколько участников ДТП?</Typography>
        <br />
        <Typography>
          <strong>Вы +1 автомобиль</strong> {'->'} Оформление Европротокола
          возможно (при отсутствии разногласий со вторым участником
          происшествия)
        </Typography>
        <br />
        <Typography>
          <strong>Вы + 2 и более автомобиля</strong> {'->'} Обязательно вызвать
          ГИБДД
        </Typography>
      </DrawerComponent>

      <DrawerComponent isShow={isOsagoShow} onCancel={osagoClose}>
        <Typography fontWeight={600}>
          ОСАГО есть у каждого из участников ДТП?
        </Typography>
        <br />
        <Typography>
          <strong>Да</strong> {'->'} Оформление Европротокола возможно
        </Typography>
        <Typography>
          <strong>Нет</strong> {'->'} Обязательно вызвать ГИБДД
        </Typography>
      </DrawerComponent>

      <DrawerComponent
        isShow={isHowManyElementsDamagedShow}
        onCancel={howManyElementsDamagedClose}
      >
        <Typography fontWeight={600}>
          Сколько элементов повреждено на автомобиле парка?
        </Typography>
        <br />
        <Typography>
          <strong>
            Поврежден 1 элемент или есть царапины на 1-2 элементах
          </strong>{' '}
          {'->'} Оформление Европротокола возможно
        </Typography>
        <Typography>
          <strong>Повреждено несколько элементов</strong> {'->'} Обязательно
          вызвать ГИБДД
        </Typography>
        <br />
        <Typography fontWeight={600}>
          🚨 После завершения оформления документов по ДТП, доставьте авто в
          парк, если не рабочее время — в ближайший рабочий день к 9:00. Если
          требуется эвакуатор, наша Служба поддержки поможет вам его вызвать.
        </Typography>
        <br />
        <Typography>
          <strong>
            ✅ Если в ДТП виноваты не Вы, парк 369 компенсирует время простоя с
            момента ДТП до замены авто в парке.
          </strong>
        </Typography>
        <br />
        <Typography>
          ❗️Компенсация выплачивается по факту предоставления постановления о
          вашей невиновности и своевременной доставки авто на базу в день
          фиксации ДТП или в ближайший рабочий день к 9:00. При несоблюдении
          времени в компенсации простоя будет отказано.
        </Typography>
      </DrawerComponent>

      <DrawerComponent
        isShow={isEuropeanProtocolShow}
        onCancel={europeanProtocolClose}
        isAutoHeight={false}
      >
        <Typography fontWeight={600}>Европротокол</Typography>
        <br />
        <Typography fontWeight={600}>
          Парк рекомендует всегда вызывать ГИБДД, но в целях экономии времени
          допускается оформление Европротокола при соблюдении условий, описанных
          в разделе (Вы стали участником ДТП, что делать?)
        </Typography>
        <br />
        <Typography fontWeight={600}>
          Правильно заполненный протокол - ваша финансовая безопасность!{' '}
          <Button
            target='_blank'
            href='https://prod-lkdriver-apark-pro.obs.ru-moscow-1.hc.sbercloud.ru/oformlenie_dtp_po_evroprotokolu_03_big.jpg'
            sx={{ m: 0, p: 0 }}
          >
            Пример заполнения
          </Button>
        </Typography>
        <br />
        <Typography fontWeight={600}>Правила заполнения:</Typography>
        <br />
        <Typography>
          • Для заполнения понадобится шариковая ручка любого цвета. Фломастер,
          карандаш или гелевая ручка — не подойдут.
        </Typography>
        <br />
        <Typography>
          • Заполняйте бланк разборчиво и печатными буквами. Обратите внимание:
          с 9 пункта каждый участник ДТП заполняет свое поле самостоятельно!
        </Typography>
        <br />
        <Typography>
          • Старайтесь не делать ошибок и исправлений при заполнении, чтобы
          избежать неверного трактования.
        </Typography>

        <br />
        <Typography>
          • Если вам не хватило места на бланке, можно продолжить записи на
          чистом листе бумаги. На основном бланке оставьте пометку: «С
          приложением», а на дополнительном — «Приложение к извещению о ДТП» и
          число.
        </Typography>
        <br />
        <Typography fontWeight={600}>
          ❗️Важно: строки, которые остались незаполненными, перечеркните, чтобы
          никто не смог туда добавить никакую информацию.
        </Typography>
      </DrawerComponent>
    </>
  )
}
