import { styled, Dialog as MuiDialog } from '@mui/material'
import { BODY_MAX_WIDTH } from '~/shared/ui/theme'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    width: calc(100% - 32px);
    max-width: calc(${BODY_MAX_WIDTH} - 32px);
    background: ${myPalette.aliceBlue.main};
    padding: 24px 16px;
    border-radius: 16px;
    overflow-x: hidden;
    margin: 0;
  }
`
