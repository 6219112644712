import { createDomain } from 'effector'

const domain = createDomain('notificationModal')

type NotificationModalType =
  | {
      variant: 'success' | 'error'
      buttonTitle?: string
      onClose?: () => Promise<void> | void
    } & ({ title: string } | { description: string })

export const notificationModalOpen = domain.createEvent<NotificationModalType>()
export const notificationModalClose = domain.createEvent()
export const $notificationModal = domain
  .createStore<NotificationModalType | null>(null)
  .on(notificationModalOpen, (_, config) => config)
  .on(notificationModalClose, () => null)
