import React from 'react'
import VerificationInput, {
  VerificationInputProps,
} from 'react-verification-input'
import { Wrapper } from './styled'

export function VerificationFieldElement(props: VerificationInputProps) {
  return (
    <Wrapper>
      <VerificationInput
        inputProps={{ name: 'code', autoComplete: 'off' }}
        length={4}
        validChars='0-9'
        placeholder=' '
        {...props}
      />
    </Wrapper>
  )
}
