import { IonFooter, IonHeader, IonMenuToggle } from '@ionic/react'
import MailIcon from '@mui/icons-material/Mail'
import { Badge, Typography } from '@mui/material'
import { additionalLinks } from '~/widgets/layout/main/ui/Menu/constants'
import { MenuType } from '~/widgets/layout/main/ui/Menu/type'
import { pushFx } from '~/shared/lib/history'
import { MenuLogoIcon } from '~/shared/ui/icons'
import { MAIN_LAYOUT_MENU_ID } from '~/shared/ui/theme/theme'
import { useMenu } from './hooks/useMenu'
import {
  AdditionalLink,
  IonToolbarCustom,
  IconWrapper,
  IonContentCustom,
  ItemWrapper,
  ItemTitle,
  IonMenuCustom,
} from './styled'

export function Menu() {
  const menus = useMenu()

  const handleMenuSelected = async (menu: MenuType) => {
    if (menu.fn) {
      menu.fn()
    }
    if (menu.to) {
      pushFx(menu.to)
    }
    if (menu.href) {
      window.open(menu.href, '_blank')
    }
  }

  return (
    <IonMenuCustom
      menuId='main-menu'
      contentId={MAIN_LAYOUT_MENU_ID}
      maxEdgeStart={0}
    >
      <IonHeader>
        <IonToolbarCustom>
          <MenuLogoIcon />
        </IonToolbarCustom>
      </IonHeader>

      <IonContentCustom fullscreen>
        {menus.map((menu) => (
          <IonMenuToggle
            key={menu.title}
            autoHide
            onClick={() => handleMenuSelected(menu)}
          >
            <ItemWrapper>
              <IconWrapper>{menu.icon || <MailIcon />}</IconWrapper>
              <Badge color='coral' variant='dot' invisible={!menu.notification}>
                <ItemTitle>{menu.title}</ItemTitle>
              </Badge>
            </ItemWrapper>
          </IonMenuToggle>
        ))}
      </IonContentCustom>
      <IonFooter>
        <IonToolbarCustom>
          {additionalLinks.map((link) => (
            <AdditionalLink key={link.to} to={link.to} target='_blank'>
              <Typography>{link.title}</Typography>
            </AdditionalLink>
          ))}
        </IonToolbarCustom>
      </IonFooter>
    </IonMenuCustom>
  )
}
