import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button } from '@mui/material'
import { useStoreMap } from 'effector-react'
import { profileModel } from '~/widgets/Profile'
import { formatPhoneNumber } from '~/shared/lib/utils'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { BusinessUserIcon, CarSimpleIcon, PhoneIcon } from '~/shared/ui/icons'
import { Card, Title, TitleWrapper } from './styled'

type TabsDrawerProps = {
  isShow: boolean
  onClose: () => void
  onPassportDetailsClick: () => void
  onDriverLicenseClick: () => void
}

export function TabsDrawer({
  isShow,
  onClose,
  onPassportDetailsClick,
  onDriverLicenseClick,
}: TabsDrawerProps) {
  const driverPhone = useStoreMap(profileModel.$driverProfile, (profile) =>
    profile?.getPhone(),
  )

  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Card sx={{ cursor: 'auto' }}>
        <TitleWrapper>
          <PhoneIcon />
          <Button href={`tel:+${driverPhone}`} sx={{ m: 0, ml: '12px', p: 0 }}>
            <Title data-testid='driver-phone'>
              {formatPhoneNumber(driverPhone)}
            </Title>
          </Button>
        </TitleWrapper>
      </Card>
      <Card onClick={onPassportDetailsClick}>
        <TitleWrapper>
          <BusinessUserIcon />
          <Title sx={{ ml: '12px' }}>Паспортные данные</Title>
        </TitleWrapper>
        <ChevronRightIcon />
      </Card>
      <Card onClick={onDriverLicenseClick}>
        <TitleWrapper>
          <CarSimpleIcon />
          <Title sx={{ ml: '12px' }}>Водительские права</Title>
        </TitleWrapper>
        <ChevronRightIcon />
      </Card>
    </DrawerComponent>
  )
}
