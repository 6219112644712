import { Typography } from '@mui/material'
import { Wrapper } from './styled'

export function EmptyCar() {
  return (
    <Wrapper>
      <Typography fontSize='22px' color='white' mb='24px' fontWeight={500}>
        У вас еще нет <br /> арендованного авто
      </Typography>
      <Typography fontSize='16px' color='white'>
        Для аренды обратитесь
        <br /> в ближайший офис
      </Typography>
    </Wrapper>
  )
}
