import { createDomain } from 'effector'

const domain = createDomain('entities.confirmPay.drawer')

export const openConfirmPayDrawer = domain.createEvent()
export const closeConfirmPayDrawer = domain.createEvent()

export const $isConfirmPayDrawerOpen = domain
  .createStore<boolean>(false)
  .on(openConfirmPayDrawer, () => true)
  .on(closeConfirmPayDrawer, () => false)
