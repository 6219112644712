import { Penalty } from '~/shared/api'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { Content } from './Content'

type PassportDetailsProps = {
  isShow: boolean
  onClose: () => void
  penalty?: Penalty
}

export function PenaltyDrawer({
  isShow,
  onClose,
  penalty,
}: PassportDetailsProps) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      {penalty && <Content penalty={penalty} />}
    </DrawerComponent>
  )
}
