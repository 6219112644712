import { useGate, useStore } from 'effector-react'
import { NotificationListDrawer, Notification } from '~/entities/Notification'
import {
  $isNotificationsDrawerOpen,
  closeNotificationsDrawer,
  openNotificationsDrawer,
  $isNotificationOpen,
  closeNotification,
  $notifications,
  Gate,
} from './model'

export function Notifications() {
  useGate(Gate)

  const isNotificationsDrawerOpen = useStore($isNotificationsDrawerOpen)
  const isNotificationOpen = useStore($isNotificationOpen)
  const notifications = useStore($notifications)

  const firstNotification = notifications[0]

  return (
    <>
      <NotificationListDrawer
        isShow={isNotificationsDrawerOpen}
        onClose={closeNotificationsDrawer}
        onActionClick={closeNotificationsDrawer}
      />
      <Notification
        onNotificationsOpen={openNotificationsDrawer}
        isShow={isNotificationOpen}
        onClose={closeNotification}
        notification={firstNotification}
      />
    </>
  )
}
