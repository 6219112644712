import { Box, Typography } from '@mui/material'

type EmptyListProps = {
  title?: string
  description?: string
}

export function EmptyList({ title, description }: EmptyListProps) {
  return (
    <Box
      sx={{
        textAlign: 'center',
        margin: '60px 0',
      }}
    >
      <Typography
        fontSize={18}
        fontWeight={700}
        color='midnightBlue.main'
        mb='16px'
      >
        {title || 'У вас нет штрафов'}
      </Typography>
      <Typography fontSize={14} color='midnightBlue.main'>
        {description || 'Здесь будет отображаться список штрафов'}
      </Typography>
    </Box>
  )
}
