import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'
import * as z from 'zod'

import { ApiModel } from './core'

const schema = z.object({})

export class Auth extends ApiModel<typeof schema> {
  static jsonApiType = 'auth'

  static schema = schema

  static async code(phone: string) {
    const url = `${this.getJsonApiUrl()}/code`
    const client = this.httpClient.getImplementingClient()

    return client.post(url, { phone }, {
      skipAuthRefresh: true,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    } as AxiosAuthRefreshRequestConfig)
  }
}
