import { createDomain, sample } from 'effector'
import { Gate } from '~/entities/MainBalance/model'
import { BalanceReport } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'

const domain = createDomain('features.listBalanceHistories')

export const getBalanceReportForDay = domain.createEvent<string>()
export const getBalanceReportForDayFx = domain.createEffect<
  string,
  BalanceReport
>({
  handler: BalanceReport.my,
})

export const $balanceReportForDay = domain
  .createStore<BalanceReport | null>(null)
  .on(getBalanceReportForDayFx.doneData, (store, balance) => balance)
  .on(Gate.close, () => null)

sample({
  clock: getBalanceReportForDay,
  target: getBalanceReportForDayFx,
})

sample({
  clock: getBalanceReportForDayFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получения данных по текущему балансу за день',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})
