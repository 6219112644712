import { Box } from '@mui/material'
import { useStore } from 'effector-react'
import { CarImage, Descriptions, EmptyCar } from '~/entities/MainCar'
import { mainCarModel } from '~/entities/MainCar'
import { ProgressBlock } from '~/shared/ui/Loaders'

export function MainCar() {
  const rentalContractPending = useStore(
    mainCarModel.getRentalContractFx.pending,
  )
  const rentalContract = useStore(mainCarModel.$rentalContract)
  const carImage = rentalContract?.getCarImage()

  if (rentalContractPending) return <ProgressBlock height={364} />
  if (!rentalContractPending && !rentalContract) return <EmptyCar />

  return (
    <Box>
      {carImage && <CarImage src={carImage} />}
      <Descriptions />
    </Box>
  )
}
