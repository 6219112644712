import { createDomain, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { Driver } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'

export const Gate = createGate()

const domain = createDomain('widgets.profile')

export const openProfileDrawer = domain.createEvent()
export const closeProfileDrawer = domain.createEvent()

export const $isProfileDrawerOpen = domain
  .createStore<boolean>(false)
  .on(openProfileDrawer, () => true)
  .on(closeProfileDrawer, () => false)

export const getDriverProfile = domain.createEvent()
export const getDriverProfileFx = domain.createEffect({
  async handler() {
    return await Driver.me()
  },
})

export const $driverProfile = createStore<Driver | null>(null)
  .on(getDriverProfileFx.doneData, (_, driverProfile) => driverProfile)
  .on(Gate.close, () => null)

sample({
  clock: Gate.open,
  target: getDriverProfile,
})

sample({
  clock: getDriverProfile,
  target: getDriverProfileFx,
})

sample({
  clock: getDriverProfileFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получения данных пользователя',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $driverProfilePending = createStore<boolean>(true).on(
  [getDriverProfileFx.doneData, getDriverProfileFx.failData],
  () => false,
)
