import { Button, Typography } from '@mui/material'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { WrapperMap } from '../styled'

type Props = {
  isShow: boolean
  onCancel: () => void
}

export function RostovDrawer({ isShow, onCancel }: Props) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onCancel}>
      <Typography fontWeight={600}>Адрес и контакты парка:</Typography>
      <Typography>
        г.Ростов-на-Дону, ул. Малиновского, 54а{' '}
        <WrapperMap>
          (
          <Button
            href='https://yandex.ru/maps/-/CDVFAR-j'
            target='_blank'
            sx={{ m: 0, p: 0 }}
          >
            Яндекс-карты
          </Button>
          )
        </WrapperMap>
      </Typography>
      <br />

      <Typography fontWeight={600}>Начальник колонны</Typography>
      <Typography>
        - Иван Бедусенко{' '}
        <Button href='tel:+79281981887' sx={{ m: 0, p: 0 }}>
          +7 (928) 198-18-87
        </Button>
      </Typography>
      <br />

      <Typography fontWeight={600}>Старший механик</Typography>
      <Typography>
        - Сергей Максимов{' '}
        <Button href='tel:+79637133296' sx={{ m: 0, p: 0 }}>
          +7 (963) 713-32-96
        </Button>
      </Typography>
    </DrawerComponent>
  )
}
