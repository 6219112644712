import { CreditCard } from '@mui/icons-material'
import { Divider, Stack, Typography } from '@mui/material'

type PayCardContentProps = {
  cardNumber: string
}

export function PayCardContent({ cardNumber }: PayCardContentProps) {
  return (
    <Stack
      direction='row'
      alignItems='center'
      divider={<Divider orientation='vertical' flexItem />}
      spacing={2}
      sx={{ width: '100%' }}
    >
      <CreditCard />
      <Typography color='black.main' fontSize={18} fontWeight='bold'>
        {cardNumber}
      </Typography>
    </Stack>
  )
}
