import { Box, styled } from '@mui/material'

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DataWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
`
