import { useStore } from 'effector-react'
import { useState } from 'react'
import { PenaltyListDrawer, PenaltyDrawer } from '~/entities/Penalties'
import { Penalty } from '~/shared/api'
import { useDrawer } from '~/shared/ui/Drawer'
import { $isPenaltiesDrawerOpen, closePenaltiesDrawer } from './model'

export function Penalties() {
  const isFineOpen = useStore($isPenaltiesDrawerOpen)
  const [selectedPenalty, setSelectedPenalty] = useState<Penalty>()
  const {
    openDrawer: penaltyDrawerOpen,
    closeDrawer: penaltyDrawerClose,
    isDrawerShow: isPenaltyDrawerShow,
  } = useDrawer()

  const handleFineDrawerOpen = (fine: Penalty) => {
    penaltyDrawerOpen()
    setSelectedPenalty(fine)
  }

  return (
    <>
      <PenaltyListDrawer
        isShow={isFineOpen}
        onClose={closePenaltiesDrawer}
        onPenaltyClick={handleFineDrawerOpen}
      />
      <PenaltyDrawer
        isShow={isPenaltyDrawerShow}
        onClose={penaltyDrawerClose}
        penalty={selectedPenalty}
      />
    </>
  )
}
