import { Typography } from '@mui/material'
import { DrawerComponent } from '~/shared/ui/Drawer'

type CarNotStartDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function CarNotStartDrawer({ isShow, onClose }: CarNotStartDrawerProps) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Typography fontWeight={600}>Возможные причины:</Typography>
      <br />
      <Typography fontWeight={600}>1. Блокировка авто</Typography>
      <Typography>
        Проверьте ваш баланс, если допущена задолженность свыше 1500 руб.
        автомобиль блокируется автоматически. Для разблокировки достаточно
        пополнить баланс на сумму долга и через 5-10 минут автомобиль будет
        готов к работе.
        <br />
        <br />
        Если прошло более 10 минут с момента пополнения баланса и автомобиль
        остается заблокированным, обратитесь, пожалуйста, в Службу поддержки
        вашего города
      </Typography>
      <br />
      <Typography fontWeight={600}>2. Низкий заряд аккумулятора</Typography>
      <Typography>
        Попробуйте “прикурить” аккумулятор самостоятельно, если нет такой
        возможности, обратитесь в Службу поддержки, вам вызовут помощь на
        дорогах или эвакуатор.
      </Typography>
    </DrawerComponent>
  )
}
