import { SplashScreen } from '@capacitor/splash-screen'
import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from '~/app'
import { initSentry } from '~/shared/lib/sentry'

initSentry()
setTimeout(SplashScreen.hide, 1000)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
