import { z } from 'zod'

import { ApiModel } from './core'

const schema = z.object({
  customerId: z.string(),
  status: z.string(),
  bankCardId: z.string(),
  expireDate: z.string(),
  maskedNumber: z.string(),
})

export type PayCardAttributes = z.infer<typeof schema>

export class PayCard extends ApiModel<typeof schema> {
  static jsonApiType = 'card'

  static schema = schema

  getBankCardId() {
    return this.getAttribute('bankCardId')
  }

  static async list() {
    const url = `${PayCard.getJsonApiUrl()}/list`
    const response = await PayCard.effectiveHttpClient.get(url)
    const array = response.getData().data
    return array.map(
      (data: PayCardAttributes) => new PayCard(data, data.bankCardId),
    )
  }

  static async getAddLink(): Promise<string> {
    const url = `${PayCard.getJsonApiUrl()}/add-link`
    const response = await PayCard.effectiveHttpClient.get(url)
    const { addCardUrl } = response?.getData().data as { addCardUrl: string }
    return addCardUrl
  }

  static async remove(bankCardId: UniqueId) {
    const url = `${PayCard.getJsonApiUrl()}/remove`
    await PayCard.effectiveHttpClient.post(url, { bankCardId })
  }
}
