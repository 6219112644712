import { Box, CircularProgress } from '@mui/material'
import { CircularProgressProps } from '@mui/material/CircularProgress'
import { isString } from '~/shared/lib/utils'

type ProgressHeadingProps = {
  height?: number | string
  size?: number
  color?: CircularProgressProps['color']
}

export function ProgressBlock({
  height = 300,
  size,
  color = 'limeGreen',
}: ProgressHeadingProps) {
  return (
    <Box
      sx={{
        height: isString(height) ? height : `${height}px`,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress color={color} size={size} />
    </Box>
  )
}
