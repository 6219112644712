import { Box, styled, Snackbar as MuiSnackbar } from '@mui/material'
import { BODY_MAX_WIDTH } from '~/shared/ui/theme'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Snackbar = styled(MuiSnackbar)`
  width: 100%;
  max-width: ${BODY_MAX_WIDTH};
  left: 0;
  right: 0;
`

export const Wrapper = styled(Box)`
  background-color: ${myPalette.white.main};
  border-radius: 8px;
  width: 100%;
  padding: 16px 20px;
  box-shadow: 0 0 5px ${myPalette.dark.shadowLight};
`

export const ActionsBlock = styled(Box)`
  display: flex;
  justify-content: space-between;
`
