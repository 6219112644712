import { styled, Stack } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Wrapper = styled(Stack)`
  background-color: ${myPalette.white.main};
  border-radius: 12px;
  box-shadow: 0 0 5px 0 #0015461a;
  padding: 16px;
  width: 100%;
  height: 56px;
  max-height: 56px;
`
