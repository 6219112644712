import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { Penalty } from '~/shared/api'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { SCROLLABLE_LIST_PAGE_KEY } from '~/shared/ui/ScrollableList'
import { Content } from './Content'

type FineListDrawerProps = {
  isShow: boolean
  onClose: () => void
  onPenaltyClick: (penalty: Penalty) => void
}

export function PenaltyListDrawer({
  isShow,
  onClose,
  onPenaltyClick,
}: FineListDrawerProps) {
  const [, setPageQuery] = useQueryParam(
    SCROLLABLE_LIST_PAGE_KEY,
    withDefault(NumberParam, 1),
  )

  const handleClose = () => {
    setPageQuery(undefined)
    onClose()
  }

  return (
    <DrawerComponent
      isShow={isShow}
      onCancel={handleClose}
      contentSx={{ height: '500px', overflowY: 'auto' }}
      autoHeightContent={{
        className: 'ion-content-scroll-host',
        role: 'feed',
      }}
    >
      <Content onPenaltyClick={onPenaltyClick} />
    </DrawerComponent>
  )
}
