import { styled, Typography } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Label = styled(Typography)`
  font-size: 12px;
  color: ${myPalette.mutedText.main};
  margin-bottom: 4px;
`

export const Field = styled(Typography)`
  font-size: 16px;
  color: ${myPalette.midnightBlue.main};
  margin-bottom: 16px;
  line-height: 24px;
`
