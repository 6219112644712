import { styled, Typography } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
`

export const Description = styled(Typography)`
  color: ${myPalette.midnightBlue.main};
  font-size: 16px;
  margin-bottom: 24px;
`
