import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const MainBox = styled(Box)(() => ({
  backgroundColor: myPalette.aliceBlue.main,
  width: '100%',
  minHeight: '100vh',
}))

export const PageHeader = styled(Box)(() => ({
  backgroundColor: myPalette.midnightBlue.main,
  color: myPalette.white.main,
  height: '293px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const ContentWrapper = styled(Box)(() => ({
  backgroundColor: myPalette.aliceBlue.main,
  borderRadius: '20px 20px 0 0',
  padding: '16px',
  marginTop: '-20px',
}))
