import TelegramIcon from '@mui/icons-material/Telegram'
import { ReactNode } from 'react'
import { PhoneIcon } from '~/shared/ui/icons'

type SupportType = {
  icon: ReactNode
  label: string
} & ({ type: 'phone'; phone: string } | { type: 'link'; link: string })

export const items: SupportType[] = [
  {
    icon: <PhoneIcon />,
    label: 'Санкт-Петербург',
    type: 'phone',
    phone: '+7 (812) 605-93-69',
  },
  {
    icon: <PhoneIcon />,
    label: 'Ростов-на-Дону',
    type: 'phone',
    phone: '+7 (863) 333-90-36',
  },
  {
    icon: <PhoneIcon />,
    label: 'Москва',
    type: 'phone',
    phone: '+7 (495) 236-90-10',
  },
  {
    icon: <PhoneIcon />,
    label: 'Краснодар',
    type: 'phone',
    phone: '+7 (861) 212-36-90',
  },
  {
    icon: <TelegramIcon />,
    label: 'Чат службы поддержки водителей в Telegram',
    type: 'link',
    link: 'https://t.me/taxi369bot',
  },
]
