import { Typography } from '@mui/material'
import { DrawerComponent } from '~/shared/ui/Drawer'

type TrafficPoliceFinesDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function TrafficPoliceFinesDrawer({
  isShow,
  onClose,
}: TrafficPoliceFinesDrawerProps) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Typography>
        Штрафы ГИБДД за нарушение правил дорожного движения поступают в
        приложение в течение двух недель с момента правонарушения, детализация
        всегда вам доступна.
      </Typography>
      <br />
      <Typography>
        Самостоятельно проводить оплату на сторонних ресурсах вам{' '}
        <strong>не нужно</strong>, т.к. это централизованно делает парк по всем
        штрафам компании.
      </Typography>
      <br />
      <Typography>
        <strong>Важно!</strong> Комиссия за оплату - 10% (минимум 50 руб). На
        крупные штрафы от 5 000 руб. действует рассрочка по 500 руб./день.
        Например, штраф за проезд на запрещающий сигнал светофора в 5 000 руб.
        будет списан с вашего баланса частями.
      </Typography>
      <br />
      <Typography>
        Будьте внимательны, не все штрафы могут быть списаны со скидкой в 50%,
        т.к. выставляются на юр.лицо, а не водителя и в большинстве случаев,
        являются повторным правонарушением.
      </Typography>
    </DrawerComponent>
  )
}
