import { blue } from '@mui/material/colors'
import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'
import { myPalette } from '../myPalette'

export const MuiForm: Components<Omit<Theme, 'components'>> = {
  MuiTextField: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: '8px',

        '&.Mui-disabled': {
          color: blue[700],
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '.MuiInputBase-input': {
          height: '31px',
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      outlined: {
        height: '31px',
        lineHeight: '31px',
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {},
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
      outlined: {
        lineHeight: '31px',
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 1)',
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .Mui-disabled .MuiAutocomplete-tag': {
          opacity: '1',
        },
        '& .Mui-disabled .MuiChip-deleteIcon': {
          opacity: '0.3',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        background: myPalette.blue.paleBlue,
        borderRadius: '6px',
        borderColor: myPalette.blue.lightSteelBlue,

        '& input.Mui-disabled, .MuiSelect-select.Mui-disabled': {
          color: 'rgba(0, 0, 0, 1)',
          WebkitTextFillColor: 'rgba(0, 0, 0, 1)',
        },
      },
    },
  },
}
