import * as z from 'zod'

import { ApiModel } from './core'

const schema = z.object({ amount: z.number(), bankCardId: z.string() })

export type PayoutAttributes = z.infer<typeof schema>

export type PayoutCommissionFetch = {
  amount: number
  bankCardId: UniqueId
}

export type PayoutCommission = {
  amount: number
  commissionPercent: number
  commissionAmount: number
  amountTotal: number
  bank: string
  paySystem: string
  customerExtId: string
  bankCardId: string
  partnerType: string
  partnerId: string
}

export type PayoutCan = {
  result: boolean
  message: string
}

export type AvailableFunds = {
  driverId: string
  amount: number
  amountWithCommission: number
}

export class Payout extends ApiModel<typeof schema, PayoutAttributes> {
  static jsonApiType = 'payouts'

  static schema = schema

  static async commission(
    values: PayoutCommissionFetch,
  ): Promise<PayoutCommission> {
    const url = `${Payout.getJsonApiUrl()}/commission`
    const response = await Payout.effectiveHttpClient.post(url, values)
    return response?.getData()
  }

  static async can(amountTotal: number): Promise<PayoutCan> {
    const url = `${Payout.getJsonApiUrl()}/can`
    const response = await Payout.effectiveHttpClient.post(url, {
      amount: amountTotal,
    })
    return response.getData()
  }

  static async availableFunds(bankCardId: UniqueId): Promise<AvailableFunds> {
    const url = `${Payout.getJsonApiUrl()}/available-funds`
    const response = await Payout.effectiveHttpClient.post(url, { bankCardId })
    return response.getData()
  }
}
