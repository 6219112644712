import React, { useState } from 'react'
import Countdown from 'react-countdown'
import { CountdownRenderProps } from 'react-countdown/dist/Countdown'
import { CountdownTimeDelta } from 'react-countdown/dist/utils'
import { ButtonRepeat } from './styled'
import { useStorageRepeatCode } from './useStorageRepeatCode'

type RepeatCodeProps = {
  time?: number
  storageKey: string
  onCodeRepeat?: () => void | Promise<void>
}

export function RepeatCode({
  time = 300000,
  storageKey,
  onCodeRepeat,
}: RepeatCodeProps) {
  const { timeStorageRemove, timeStorageSet, timeStorageGet } =
    useStorageRepeatCode(storageKey)

  const timeLeftGet = () => {
    const storageTime = timeStorageGet()
    if (!storageTime) return null

    const storageTimeLeft = storageTime - Date.now()
    return storageTimeLeft > 0 ? storageTime : null
  }

  const [timeLeft, setTimeLeft] = useState<number | null>(timeLeftGet())

  const handleTick = (delta: CountdownTimeDelta) => {
    timeStorageSet(delta.total)
  }

  const handleCountDownComplete = () => {
    setTimeLeft(null)
    timeStorageRemove()
  }

  const handleRequestCodeRepeat = async () => {
    await onCodeRepeat?.()
    setTimeLeft(Date.now() + time)
    timeStorageSet(time)
  }

  return (
    <ButtonRepeat
      onClick={handleRequestCodeRepeat}
      disabled={Boolean(timeLeft)}
      variant='text'
    >
      Запросить код снова{' '}
      {timeLeft && (
        <>
          через&nbsp;
          <Countdown
            date={timeLeft}
            renderer={(values: CountdownRenderProps) => (
              <span>
                {values.formatted.minutes}:{values.formatted.seconds}
              </span>
            )}
            onComplete={handleCountDownComplete}
            onTick={handleTick}
            onStart={handleTick}
          />
        </>
      )}
    </ButtonRepeat>
  )
}
