import { GlobalStyles } from '@mui/material'
import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { myPalette } from '~/shared/ui/theme/myPalette'

type Props = {
  children?: ReactNode
}

export function MinimalLayout({ children }: Props) {
  return (
    <>
      <GlobalStyles
        styles={() => ({
          body: {
            background: myPalette.midnightBlue.main,
          },
        })}
      />
      {children || <Outlet />}
    </>
  )
}
