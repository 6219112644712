import { Box, styled, Typography } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const ImgsWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin-bottom: 8px;
`

export const ImgWrapper = styled(Box)`
  display: flex;
  cursor: pointer;

  img {
    object-fit: contain;
  }
`

export const Title = styled(Typography)`
  font-size: 16px;
  color: ${myPalette.midnightBlue.main};
  font-weight: 500;
  margin-bottom: 8px;
  line-height: 24px;
`

export const Field = styled(Typography)`
  font-size: 16px;
  color: ${myPalette.mutedText.main};
  margin-bottom: 8px;
  line-height: 24px;
`
