import { Box, styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled(Box)`
  display: flex;
  width: 100%;

  form {
    width: 100%;
  }

  input[type='submit'] {
    cursor: pointer;
    border: none;
    background: ${myPalette.limeGreen.main};
    border-radius: 8px;
    padding: 14px 24px;
    color: ${myPalette.midnightBlue.main};
    font-size: 16px;
    font-weight: 600;
    width: 100%;
  }
`
