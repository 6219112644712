export function SuccessIcon() {
  return (
    <svg
      width='64'
      height='63'
      viewBox='0 0 64 63'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='3.125'
        y='2.625'
        width='57.75'
        height='57.75'
        rx='28.875'
        fill='#D6F337'
        stroke='#D6F337'
        strokeWidth='4.8125'
      />
      <path
        d='M44.8327 25.0835L26.9663 42.9499C26.2505 43.6657 25.0588 43.5477 24.4973 42.7054L19.166 34.7085'
        stroke='#021542'
        strokeWidth='4.8125'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
