import { Button, Typography } from '@mui/material'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { WrapperMap } from '../styled'

type Props = {
  isShow: boolean
  onCancel: () => void
}

export function KrasnodarDrawer({ isShow, onCancel }: Props) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onCancel}>
      <Typography fontWeight={600}>Адрес и контакты парка:</Typography>
      <Typography>
        г. Краснодар, ул. Жлобы, 119{' '}
        <WrapperMap>
          (
          <Button
            href='https://yandex.ru/maps/-/CDFDiHp5'
            target='_blank'
            sx={{ m: 0, p: 0 }}
          >
            Яндекс-карты
          </Button>
          )
        </WrapperMap>
      </Typography>
      <br />

      <Typography fontWeight={600}>Начальник колонны</Typography>
      <Typography>
        - Тарасенко Даниил{' '}
        <Button href='tel:+79251135675' sx={{ m: 0, p: 0 }}>
          +7 (925) 113-56-75
        </Button>
      </Typography>
      <br />

      <Typography fontWeight={600}>Старший механик</Typography>
      <Typography>
        - Гончарук Игорь{' '}
        <Button href='tel:+79637137199' sx={{ m: 0, p: 0 }}>
          +7 (963) 713-71-99
        </Button>
      </Typography>
    </DrawerComponent>
  )
}
