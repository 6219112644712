import { ReactNode, useState } from 'react'
import { WrapperButtonGroup, Button } from './styled'

type ButtonGroupProps<T extends string> = {
  options: {
    key: T
    template: ReactNode
  }[]
  onChange: (key?: T) => void
}

export function ButtonGroup<T extends string>({
  options,
  onChange,
}: ButtonGroupProps<T>) {
  const [selectedKey, setSelectedKeyKey] = useState<string>()

  const handleSelected = (buttonKey: T) => {
    const key = selectedKey === buttonKey ? undefined : buttonKey
    setSelectedKeyKey(key)
    onChange(key)
  }

  return (
    <WrapperButtonGroup variant='text' fullWidth disableFocusRipple>
      {options.map((option) => (
        <Button
          select={selectedKey === option.key}
          fullWidth
          variant='whiteFlooded'
          key={option.key}
          onClick={() => handleSelected(option.key)}
        >
          {option.template}
        </Button>
      ))}
    </WrapperButtonGroup>
  )
}
