export function ErrorIcon() {
  return (
    <svg
      width='64'
      height='63'
      viewBox='0 0 64 63'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='3.125'
        y='2.625'
        width='57.75'
        height='57.75'
        rx='28.875'
        fill='#F46857'
        stroke='#F46857'
        strokeWidth='4.8125'
      />
      <path
        d='M30.5088 35.736L30.1248 20.376H33.8368L33.4528 35.736H30.5088ZM31.9808 43.256C31.5968 43.256 31.2341 43.1707 30.8928 43C30.5728 42.8293 30.3061 42.5733 30.0928 42.232C29.9008 41.8907 29.8048 41.5067 29.8048 41.08C29.8048 40.6107 29.9008 40.216 30.0928 39.896C30.2848 39.5547 30.5514 39.2987 30.8928 39.128C31.2341 38.9573 31.6074 38.872 32.0128 38.872C32.5888 38.872 33.0901 39.0747 33.5168 39.48C33.9648 39.864 34.1888 40.3973 34.1888 41.08C34.1888 41.5067 34.0821 41.8907 33.8688 42.232C33.6768 42.5733 33.4101 42.8293 33.0688 43C32.7488 43.1707 32.3861 43.256 31.9808 43.256Z'
        fill='white'
      />
    </svg>
  )
}
