import { styled } from '@mui/material'
import { NumericFormatElement as DefaultNumericFormatElement } from '~/shared/ui/form'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const NumericFormatElement = styled(DefaultNumericFormatElement)`
  margin-bottom: 60px;

  .MuiInputBase-root {
    padding: 0;
    background-color: ${myPalette.white.main};
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -22px;
  }
`
