import { Box, Button, Typography } from '@mui/material'
import { sanitizePhoneNumber } from '~/shared/lib/utils'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { items } from './constants'
import { Card, Title } from './styled'

type DriverSupportServiceDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function DriverSupportServiceDrawer({
  isShow,
  onClose,
}: DriverSupportServiceDrawerProps) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      {items.map((item) => (
        <Card key={item.label}>
          {item.icon}
          <Box sx={{ ml: '12px' }}>
            {item.type === 'phone' && (
              <>
                <Typography fontSize={14} color='mutedText.main' mb='2px'>
                  {item.label}
                </Typography>
                <Button
                  href={`tel:+${sanitizePhoneNumber(item.phone)}`}
                  sx={{ m: 0, p: 0 }}
                >
                  <Title>{item.phone}</Title>
                </Button>
              </>
            )}
            {item.type === 'link' && (
              <Button target='_blank' href={item.link} sx={{ m: 0, p: 0 }}>
                <Typography fontSize={14} color='mutedText.main' mb='2px'>
                  {item.label}
                </Typography>
              </Button>
            )}
          </Box>
        </Card>
      ))}
    </DrawerComponent>
  )
}
