import * as z from 'zod'
import { RentalContractStatusEnum } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  carBrandTitle: z.string(),
  carModelTitle: z.string(),
  carCategoryName: z.string(),
  carPlateNumber: z.string(),
  carRentalName: z.string(),
  contractNumber: z.string(),
  firstDayIsFree: z.boolean(),
  rentPerDay: z.string(),
  workRuleTitle: z.string(),
  workSchedule: z.string(),
  carImage: z.string().optional(),
  status: z.nativeEnum(RentalContractStatusEnum),
})
const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type RentalContactAttributes = z.infer<typeof attributesSchema>

export class RentalContact extends ApiModel<
  typeof schema,
  RentalContactAttributes
> {
  static jsonApiType = 'rental-contracts'

  static schema = schema

  getCarImage(): string | undefined {
    return this.getAttribute('carImage')
  }

  static async my() {
    const url = `${RentalContact.getJsonApiUrl()}/my`
    const response = await RentalContact.effectiveHttpClient.get(url)
    const data = response?.getData().data
    return new RentalContact(data.attributes, data.id)
  }
}
