import { InfoOutlined } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useStore, useStoreMap } from 'effector-react'
import { useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { PayInfoModal, WithdrawalForm, balanceModel } from '~/entities/Balance'
import { setCommission } from '~/entities/Balance/model'
import { Payout, PayoutCommission } from '~/shared/api'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { useModal } from '~/shared/ui/Modal'
import { AmountAll } from './ui/AmountAll'
import { Commission } from './ui/Commission'
import { SelectedPayCard } from './ui/SelectedPayCard'

type WithdrawalDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function WithdrawalDrawer({ isShow, onClose }: WithdrawalDrawerProps) {
  const { isModalShow, openModal, closeModal } = useModal()
  const [amount, setAmount] = useState<number>()

  const selectedPayCard = useStoreMap(
    balanceModel.$selectedPayCard,
    (payCard) => payCard?.getAttributes(),
  )
  const isAmountAllLoading = useStore(balanceModel.getAvailableFundsFx.pending)
  const availableFunds = useStore(balanceModel.$availableFunds)
  const isTransactionCanPending = useStore(
    balanceModel.transactionCanFx.pending,
  )

  const gridKey = useMemo(
    () => ['getWithdrawalCommission', amount, selectedPayCard],
    [amount, selectedPayCard],
  )

  const [debounceGridKey] = useDebounce(gridKey, 400)

  const {
    data: resCommission,
    isFetching,
    remove,
  } = useQuery<PayoutCommission>(
    debounceGridKey,
    () =>
      Payout.commission({
        amount: amount as number,
        bankCardId: selectedPayCard?.bankCardId as UniqueId,
      }),
    {
      enabled: Boolean(amount && selectedPayCard?.bankCardId),
      retry: false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  )

  const handleDrawerClose = () => {
    setAmount(undefined)
    remove()
    onClose()
  }

  useEffect(() => {
    setCommission(resCommission && amount ? resCommission : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resCommission])

  return (
    <DrawerComponent isShow={isShow} onCancel={handleDrawerClose}>
      <Box sx={{ textAlign: 'center', marginBottom: '44px' }}>
        <Stack justifyContent='center' alignItems='center' direction='row'>
          <Typography component='span' fontSize={12}>
            Доступно к снятию в сутки
          </Typography>
          <InfoOutlined onClick={openModal} sx={{ ml: 1, cursor: 'pointer' }} />
        </Stack>

        <AmountAll />
      </Box>

      <Typography fontSize={16} marginBottom='16px'>
        Вывести на карту:
      </Typography>

      <SelectedPayCard />

      <WithdrawalForm
        isSubmitting={isFetching || isTransactionCanPending}
        onSuccess={balanceModel.transactionCan}
        amountAll={availableFunds?.amountWithCommission || 0}
        isAmountAllLoading={isAmountAllLoading}
        onSumChange={setAmount}
        disable={!selectedPayCard || !resCommission}
        additionalContent={<Commission isLoading={isFetching} />}
      />

      <PayInfoModal isShow={isModalShow} onCancel={closeModal} />
    </DrawerComponent>
  )
}
