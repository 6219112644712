import { IonContent, IonMenu, IonToolbar } from '@ionic/react'
import { styled, Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const IonMenuCustom = styled(IonMenu)`
  --background: ${myPalette.aliceBlue.main};

  &::part(backdrop) {
    --background: rgba(0, 0, 0, 0.87);
  }
`

export const IonToolbarCustom = styled(IonToolbar)`
  --background: ${myPalette.aliceBlue.main};
  --border-width: 0 !important;
  --padding-end: 0;
  --padding-start: 16px;
  --padding-bottom: 0;
  --padding-top: 26px;
  --box-shadow: none;
`

export const IconWrapper = styled(Box)`
  padding: 0;
  margin-right: 16px;
`
export const ItemWrapper = styled(Box)`
  border-bottom: 1px solid ${myPalette.dark.drawerTopLine};
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 68px;
  width: 100%;
`

export const ItemTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  width: 100%;
`

export const IonContentCustom = styled(IonContent)`
  --padding-top: 54px;
  --padding-start: 16px;
  --padding-end: 16px;
  --background: ${myPalette.aliceBlue.main};
`

export const AdditionalLink = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  display: block;
  color: ${myPalette.black.main};
  margin-bottom: 24px;
`
