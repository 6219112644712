import * as z from 'zod'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  protocolDate: dateSchema,
  protocolNumber: z.string(),
  odpsCode: z.string(),
  odpsName: z.string(),
  odpsAddr: z.string(),
  penaltyDatetime: dateSchema,
  penaltyLocation: z.string(),
  penaltyLawArticleCode: z.string(),
  penaltyLawArticleNumber: z.string(),
  penaltyLawArticleDescription: z.string(),
  carPlateNumber: z.string(),
  carVin: z.string(),
  paymentUin: z.string(),
  paymentAmount: z.string(),
  paymentDiscountSize: z.number(),
  paymentDiscountDate: dateSchema,
  paymentAmountTotal: z.string(),
  paymentAmountTotalDiscount: z.string(),
  paymentUntilDate: dateSchema,
  paymentIsPaidPartial: z.boolean(),
  paymentIsPaid: z.boolean(),
  paymentAmountDriver: z.string(),
  files: z
    .object({
      uuid: z.string(),
      url: z.string(),
    })
    .array()
    .optional(),
})

const schema = z
  .object({
    createdAt: dateSchema,
    updatedAt: dateSchema,
  })
  .merge(attributesSchema)

export type PenaltyAttributes = z.infer<typeof attributesSchema>

export class Penalty extends ApiModel<typeof schema, PenaltyAttributes> {
  static jsonApiType = 'penalties'

  static schema = schema

  getPenaltyDatetime(): string {
    return this.getAttribute('penaltyDatetime')
  }
  getPenaltyLawArticleNumber(): string {
    return this.getAttribute('penaltyLawArticleNumber')
  }
  getPenaltyLawArticleDescription(): string {
    return this.getAttribute('penaltyLawArticleDescription')
  }
  getPaymentAmountDriver(): string {
    return this.getAttribute('paymentAmountDriver')
  }
  getFiles(): PenaltyAttributes['files'] {
    return this.getAttribute('files')
  }
  getOdpsName(): string {
    return this.getAttribute('odpsName')
  }
  getCarVin(): string {
    return this.getAttribute('carVin')
  }
  getProtocolNumber(): string {
    return this.getAttribute('protocolNumber')
  }
}
