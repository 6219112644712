import { Badge, Box, Button, Typography } from '@mui/material'
import React from 'react'
import { NotificationType } from '~/entities/Notification'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { ActionsBlock, TitleWrapper } from './styled'

type NotificationListDrawerProps = {
  isShow: boolean
  onClose: () => void
  onActionClick: () => void
}

const list: NotificationType[] = [
  {
    id: '1',
    title: 'Вам необходимо записаться на ТО',
    description:
      'Ваш километраж превысил 8000 км. Выбрать дату прохождения ТО вы можете во вкладке профиля.',
    date: '12.04.2022',
    actions: 'Записаться',
  },
  {
    id: '2',
    title: 'Ваш друг присоединился к реферальной программе',
    description:
      'Дулмачев Константин Сергеевич присоединился к нам по реферальной программе. По истечению трёх дней вам начислится 1500 ₽.',
    date: '12.04.2022',
    actions: 'Реферальная программа',
  },
  {
    id: '3',
    title: 'Ваш друг присоединился к реферальной программе',
    description:
      'Дулмачев Константин Сергеевич присоединился к нам по реферальной программе. По истечению трёх дней вам начислится 1500 ₽.',
    date: '12.04.2022',
    actions: 'Реферальная программа',
  },
  {
    id: '4',
    title: 'Ваш друг присоединился к реферальной программе',
    description:
      'Дулмачев Константин Сергеевич присоединился к нам по реферальной программе. По истечению трёх дней вам начислится 1500 ₽.',
    date: '12.04.2022',
    actions: 'Реферальная программа',
  },
  {
    id: '5',
    title: 'Ваш друг присоединился к реферальной программе',
    description:
      'Дулмачев Константин Сергеевич присоединился к нам по реферальной программе. По истечению трёх дней вам начислится 1500 ₽.',
    date: '12.04.2022',
    actions: 'Реферальная программа',
  },
  {
    id: '6',
    title: 'Ваш друг присоединился к реферальной программе',
    description:
      'Дулмачев Константин Сергеевич присоединился к нам по реферальной программе. По истечению трёх дней вам начислится 1500 ₽.',
    date: '12.04.2022',
    actions: 'Реферальная программа',
  },
]

export function NotificationListDrawer({
  isShow,
  onClose,
  onActionClick,
}: NotificationListDrawerProps) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onClose} isAutoHeight={false}>
      {list.map((notification) => (
        <Box key={notification.id} sx={{ mb: '24px' }}>
          <TitleWrapper>
            <Typography
              fontSize={18}
              fontWeight={700}
              color='midnightBlue.main'
              mb='4px'
              lineHeight='20.8px'
            >
              {notification.title}
            </Typography>
            <Badge sx={{ left: '-4px' }} color='coral' variant='dot' />
          </TitleWrapper>
          <Typography
            fontSize={16}
            color='midnightBlue.main'
            mb='8px'
            lineHeight='24px'
          >
            {notification.description}
          </Typography>

          <ActionsBlock>
            <Button sx={{ p: 0 }} onClick={onActionClick}>
              <Typography fontSize={14} fontWeight={500} color='blue.main'>
                {notification.actions}
              </Typography>
            </Button>
            <Typography fontSize={12} color='mutedText.main'>
              {notification.date}
            </Typography>
          </ActionsBlock>
        </Box>
      ))}
    </DrawerComponent>
  )
}
