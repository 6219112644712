import { createDomain } from 'effector'

const domain = createDomain('entities.confirmWithdrawal.drawer')

export const openConfirmWithdrawalDrawer = domain.createEvent()
export const closeConfirmWithdrawalDrawer = domain.createEvent()

export const $isConfirmWithdrawalDrawerOpen = domain
  .createStore<boolean>(false)
  .on(openConfirmWithdrawalDrawer, () => true)
  .on(closeConfirmWithdrawalDrawer, () => false)
