import * as z from 'zod'
import { dateSchema, phoneScheme, uuidOptionSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  lastName: z.string().trim().min(1, 'Обязательное поле'),
  firstName: z.string().trim().min(1, 'Обязательное поле'),
  middleName: z.string().optional().nullable(),
  birthDate: dateSchema,
  phone: phoneScheme,
  drivingExperience: z.string().optional(),
  drivingLicenseNumber: z.string().trim().min(1, 'Обязательное поле'),
  drivingExperienceStartDate: dateSchema,
  // Паспортные данные
  passportNumber: z.string().trim().min(10, 'Длина поля - 10 символов'),
  passportIssueDate: dateSchema,
  passportExpirationDate: dateSchema,
  passportOrganization: z.string().trim().min(1, 'Обязательное поле'),
})

const relationsSchema = z.object({
  carId: uuidOptionSchema,
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)
  .merge(relationsSchema)

export type RentalAttributes = z.infer<typeof attributesSchema>

export class Rental extends ApiModel<typeof schema, RentalAttributes> {
  static jsonApiType = 'rentals'

  static schema = schema
}
