import { useEffect } from 'react'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { pushFx } from '~/shared/lib/history'

export function AppUrlListener() {
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Example url: https://driver.apark.pro
      const router = event.url.split('.pro').pop()
      if (router) {
        pushFx(router)
      }
    })
  }, [])

  return null
}
