import * as z from 'zod'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  comingAmount: z.number(),
  spendingAmount: z.number(),
  profitAmount: z.number(),
})
const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type balanceReportAttributes = z.infer<typeof attributesSchema>

export class BalanceReport extends ApiModel<
  typeof schema,
  balanceReportAttributes
> {
  static jsonApiType = 'balance-reports'

  static schema = schema

  getComingAmount(): number {
    return this.getAttribute('comingAmount')
  }

  getSpendingAmount(): number {
    return this.getAttribute('spendingAmount')
  }

  getProfitAmount(): number {
    return this.getAttribute('profitAmount')
  }

  static async my(currentDate: string) {
    const url = `${BalanceReport.getJsonApiUrl()}/my/?filter[periodFrom]=${currentDate}&filter[periodTo]=${currentDate}&filter[type]=main`
    const response = await BalanceReport.effectiveHttpClient.get(url)
    const data = response?.getData().data
    return new BalanceReport(data.attributes, data.id)
  }
}
