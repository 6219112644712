import { Typography } from '@mui/material'
import { useGate } from 'effector-react'
import { ListBankCards } from '~/features/Balance/ListBankCards'
import { balanceModel, withdrawalModel } from '~/entities/Balance'
import { backFx } from '~/shared/lib/history'
import { PageContainer } from '~/shared/ui/PageContainer'

export function ListBankCardsPage() {
  useGate(balanceModel.Gate)

  const handleOnBack = () => {
    backFx()
    withdrawalModel.openWithdrawalDrawer()
  }
  return (
    <PageContainer
      canBack
      onBack={handleOnBack}
      title='Список банковских карт'
      topContent={
        <Typography
          color='aliceBlue.main'
          fontSize={22}
          fontWeight={700}
          align='center'
        >
          Выберите карту
        </Typography>
      }
    >
      <ListBankCards />
    </PageContainer>
  )
}
