import * as z from 'zod'
import {
  TransmissionEnum,
  FuelTypeEnum,
  CarTariffEnum,
} from '~/shared/config/enums'
import { dateSchema, uuidOptionSchema } from '~/shared/lib/schemas'
import { CarModel } from './carModel'
import { ApiModel, ToOneRelation } from './core'

const attributesSchema = z.object({
  // id: uuidOptionSchema,
  title: z.string().optional(),
  transmission: z.nativeEnum(TransmissionEnum),
  fuelType: z.nativeEnum(FuelTypeEnum),
  // year: yearSchema,
  price: z.number().optional(),
  year: z.string().optional(),
  tariff: z.nativeEnum(CarTariffEnum),
  image: z.string().optional(),
})
const relationsSchema = z.object({
  modelId: uuidOptionSchema,
  brandId: uuidOptionSchema,
})
const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)
  .merge(relationsSchema)

export type CarAttributes = z.infer<typeof attributesSchema>

export class Car extends ApiModel<typeof schema, CarAttributes> {
  static jsonApiType = 'cars'

  static schema = schema

  carModel(): ToOneRelation<CarModel, this> {
    return this.hasOne(CarModel)
  }
  getCarModel(): CarModel {
    return this.getRelation('carModel')
  }
  setCarModel(id: UniqueId) {
    const color = new CarModel()
    color.setApiId(id)
    this.setRelation('carModel', color)
  }

  getTitle() {
    // TODO настроить получение названия модели авто
    // return this.getRelation('title')
    return 'Hyundai Solaris'
  }

  getBrandAndModelTitle() {
    const model = this.getCarModel()
    const brand = model?.getCarBrand()
    return [brand?.getTitle(), model].filter(Boolean).join(' ')
  }

  getModel(): string {
    return this.getAttribute('model')?.title
  }
  getTransmission(): string {
    return this.getAttribute('transmission')
  }
  getAttributeBrand() {
    return this.getAttribute('brand')?.title
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getBrandAndModelTitle(),
    }
  }

  static async fetchOptions(search: string) {
    const response = await Car.where('carModel', search).get(1)
    return response.getData().map((o) => o.getOption())
  }
}
