import { createDomain, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { BalanceRefill } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'

const domain = createDomain('widgets.tinkoff.pay')
export const Gate = createGate()

export const getFormMetaFx = domain.createEffect<void, BalanceRefill>({
  async handler() {
    return BalanceRefill.getFormMeta()
  },
})

export const $formMeta = domain
  .createStore<BalanceRefill | null>(null)
  .on(getFormMetaFx.doneData, (_, payCards) => payCards)
  .on(Gate.close, () => null)

sample({
  clock: Gate.open,
  target: getFormMetaFx,
})

sample({
  clock: getFormMetaFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message:
        'Ошибка получения данных платежа. Пожалуйста обратитесь к администратору',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $formMetaPending = createStore<boolean>(true)
  .on([getFormMetaFx.doneData, getFormMetaFx.failData], () => false)
  .on(Gate.close, () => true)
