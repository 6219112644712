import {
  styled,
  Chip as MuiChip,
  Box,
  Typography as MuiTypography,
} from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const DataWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`

export const Chip = styled(MuiChip)`
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  color: ${myPalette.black.main};
  background: ${myPalette.white.main};
`
export const BlockTariff = styled(Box)`
  display: flex;
`

export const Typography = styled(MuiTypography)`
  color: ${myPalette.white.main};
`

export const Block = styled(Box)`
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  display: flex;
  background: ${myPalette.white.main};
  font-size: 18px;
  border-radius: 12px;
  height: 100%;
`
export const BlockTitle = styled(MuiTypography)`
  font-size: 14px;
  color: ${myPalette.mutedText.main};
`
export const BlockDescription = styled(MuiTypography)`
  font-size: 18px;
  font-weight: 500;
`
