import { Typography } from '@mui/material'
import { DrawerComponent } from '~/shared/ui/Drawer'

type BreakdownOnRoadProps = {
  isShow: boolean
  onClose: () => void
}

export function BreakdownOnRoad({ isShow, onClose }: BreakdownOnRoadProps) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Typography>
        В зависимости от характера поломки, вам нужно приехать в сервис на
        диагностику или отправить авто на эвакуаторе в парк.
      </Typography>
      <br />
      <Typography>
        <strong>Загорелся чек, но автомобиль ведет себя исправно</strong> -
        можете продолжать движение, но в ближайшее время запишитесь в сервис для
        диагностики и устранения поломки. Запись через механика - Гончарук Игорь
        7 (963) 713-71-99 (пн-пят с 9 до 18).{' '}
        <strong>Исключение - чек ABS и масла</strong>, где требуется остановить
        эксплуатацию авто и следовать согласно пункту ниже.
      </Typography>
      <br />
      <Typography>
        <strong>
          Если загорелся чек и в движении вы ощущаете неисправное поведение
          автомобиля
        </strong>
        , заглушите двигатель и сообщите в Службу поддержки водителей, за вами
        пришлют эвакуатор, аренда будет остановлена.
      </Typography>
      <br />
      <Typography>
        <strong>Вытекают жидкости/дымится машина</strong>, заглушите двигатель и
        сообщите в Службу поддержки водителей, за вами пришлют эвакуатор, аренда
        будет остановлена.
      </Typography>
    </DrawerComponent>
  )
}
