import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { PhoneIcon } from '~/shared/ui/icons'
import { Card, Title, TitleWrapper } from './styled'

type TabsDrawerProps = {
  isShow: boolean
  onClose: () => void
  onDriverSupportService: () => void
  onParkAddressAndContactsOpen: () => void
  onCarNotStartOpen: () => void
  onRoadAccidentOpen: () => void
  onBreakdownOnRoadOpen: () => void
  onDocumentsOpen: () => void
  onTrafficPoliceFinesDrawerOpen: () => void
}

export function TabsDrawer({
  isShow,
  onClose,
  onDriverSupportService,
  onParkAddressAndContactsOpen,
  onCarNotStartOpen,
  onRoadAccidentOpen,
  onBreakdownOnRoadOpen,
  onDocumentsOpen,
  onTrafficPoliceFinesDrawerOpen,
}: TabsDrawerProps) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Card onClick={onDriverSupportService}>
        <TitleWrapper>
          <PhoneIcon />
          <Title sx={{ ml: '12px' }}>Служба поддержки водителей</Title>
        </TitleWrapper>
        <ChevronRightIcon />
      </Card>
      <Card onClick={onParkAddressAndContactsOpen}>
        <TitleWrapper>
          <Title sx={{ ml: '12px' }}>Адрес и контакты парка</Title>
        </TitleWrapper>
        <ChevronRightIcon />
      </Card>
      <Card onClick={onCarNotStartOpen}>
        <TitleWrapper>
          <Title sx={{ ml: '12px' }}>Автомобиль НЕ заводится</Title>
        </TitleWrapper>
        <ChevronRightIcon />
      </Card>
      <Card onClick={onRoadAccidentOpen}>
        <TitleWrapper>
          <Title sx={{ ml: '12px' }}>ДТП</Title>
        </TitleWrapper>
        <ChevronRightIcon />
      </Card>
      <Card onClick={onBreakdownOnRoadOpen}>
        <TitleWrapper>
          <Title sx={{ ml: '12px' }}>Поломка в дороге</Title>
        </TitleWrapper>
        <ChevronRightIcon />
      </Card>
      <Card onClick={onDocumentsOpen}>
        <TitleWrapper>
          <Title sx={{ ml: '12px' }}>Документы</Title>
        </TitleWrapper>
        <ChevronRightIcon />
      </Card>
      <Card onClick={onTrafficPoliceFinesDrawerOpen}>
        <TitleWrapper>
          <Title sx={{ ml: '12px' }}>Штрафы ГИБДД</Title>
        </TitleWrapper>
        <ChevronRightIcon />
      </Card>
    </DrawerComponent>
  )
}
