import * as z from 'zod'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  amount: z.number(),
})
const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type BalanceAttributes = z.infer<typeof attributesSchema>

export class Balance extends ApiModel<typeof schema, BalanceAttributes> {
  static jsonApiType = 'balances'

  static schema = schema

  getAmount(): number {
    return this.getAttribute('amount')
  }

  static async my() {
    const url = `${Balance.getJsonApiUrl()}/my`
    const response = await Balance.effectiveHttpClient.get(url)
    const data = response?.getData().data
    return new Balance(data.attributes, data.id)
  }
}
