import { Chip as MuiChip, styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Chip = styled(MuiChip)`
  cursor: pointer;
  border-radius: 8px;
  padding: 20px 16px;
  font-size: 18px;
  font-weight: 500;
  color: ${myPalette.black.main};
  background: ${myPalette.limeGreen.main};

  &:hover {
    background-color: ${myPalette.limeGreen.main};
  }

  .MuiChip-label {
    padding-left: 10px;
    padding-right: 0;
  }
`
