export const stories = [
  {
    id: '1',
    title: 'С нами надежно и безопасно',

    img: '/images/stories/WorkWithUs.png',
  },
  {
    id: '2',
    title: 'Клуб водителей 369',
    img: '/images/stories/RentCar.png',
  },
  // {
  //   id: '3',
  //   title: 'Связь с оператором',
  //   img: '/images/stories/CommunicationWithOperator.png',
  // },
]
