import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  Box,
  IconButton,
  Link as MuiLink,
  Button,
  Skeleton,
} from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { Link } from 'react-router-dom'

import { $selectedPayCard, getPayCardsFx } from '~/entities/Balance/model'
import { PayCardContent, PayCardWrapper } from '~/shared/ui/PayCard'

export function SelectedPayCard() {
  const selectedPayCard = useStoreMap($selectedPayCard, (payCard) =>
    payCard?.getAttributes(),
  )
  const isFetchingPayCards = useStore(getPayCardsFx.pending)

  return (
    <Box sx={{ marginBottom: '32px' }}>
      {isFetchingPayCards && <Skeleton variant='rounded' height={56} />}

      {!isFetchingPayCards && selectedPayCard && (
        <PayCardWrapper>
          <PayCardContent cardNumber={selectedPayCard?.maskedNumber} />
          <MuiLink component={Link} to={`/bank-cards`}>
            <IconButton sx={{ p: 0 }}>
              <ArrowForwardIosIcon color='midnightBlue' />
            </IconButton>
          </MuiLink>
        </PayCardWrapper>
      )}

      {!isFetchingPayCards && !selectedPayCard && (
        <MuiLink component={Link} to={`/bank-cards`}>
          <Button
            fullWidth
            variant='lightSteelBlueBorder'
            size='large'
            endIcon={<ArrowForwardIosIcon color='midnightBlue' />}
            sx={{ justifyContent: 'space-between' }}
          >
            Выбрать карту
          </Button>
        </MuiLink>
      )}
    </Box>
  )
}
