export function TrashIcon() {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_4904_3820)'>
        <path
          d='M2.66699 5.1665H13.3337'
          stroke='#292A43'
          strokeWidth='1.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.66699 7.8335V11.8335'
          stroke='#292A43'
          strokeWidth='1.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.33301 7.8335V11.8335'
          stroke='#292A43'
          strokeWidth='1.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.33301 5.1665L3.99967 13.1665C3.99967 13.5201 4.14015 13.8593 4.3902 14.1093C4.64025 14.3594 4.97939 14.4998 5.33301 14.4998H10.6663C11.02 14.4998 11.3591 14.3594 11.6092 14.1093C11.8592 13.8593 11.9997 13.5201 11.9997 13.1665L12.6663 5.1665'
          stroke='#292A43'
          strokeWidth='1.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6 5.16667V3.16667C6 2.98986 6.07024 2.82029 6.19526 2.69526C6.32029 2.57024 6.48986 2.5 6.66667 2.5H9.33333C9.51014 2.5 9.67971 2.57024 9.80474 2.69526C9.92976 2.82029 10 2.98986 10 3.16667V5.16667'
          stroke='#292A43'
          strokeWidth='1.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_4904_3820'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
