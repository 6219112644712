import { Box, Stack, styled, Typography } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Title = styled(Box)`
  font-size: 16px;
  margin-bottom: 16px;
`

export const DetailBlock = styled(Stack)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const DetailItemTitle = styled(Typography)`
  font-size: 16px;
  color: ${myPalette.dark.main};
`

export const DetailAmountTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
`
