export function MenuQuestionIcon() {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='18' fill='#021542' />
      <path
        d='M24.3333 10.5715H12.6667C11.7462 10.5715 11 11.3544 11 12.32V20.5129C11 21.4786 11.7462 22.2614 12.6667 22.2614H14.75C14.9801 22.2614 15.1667 22.4571 15.1667 22.6985V25.1335C15.1667 25.5385 15.6462 25.7257 15.8986 25.4194L18.3754 22.4127C18.4545 22.3166 18.5696 22.2614 18.6906 22.2614H24.3333C25.2538 22.2614 26 21.4786 26 20.5129V12.32C26 11.3544 25.2538 10.5715 24.3333 10.5715Z'
        stroke='#E4ECFF'
        strokeWidth='1.25'
      />
      <path
        d='M18.4999 17.2382V16.3135C18.4999 16.1223 18.6324 15.9599 18.8075 15.8831C20.2283 15.2602 20.5921 13.0715 18.5 13.0715C16.264 13.0715 16.8952 14.7382 16.8952 14.7382'
        stroke='#E4ECFF'
        strokeWidth='1.25'
        strokeLinecap='round'
      />
      <circle cx='18.4998' cy='19.7381' r='0.833333' fill='#E4ECFF' />
    </svg>
  )
}
