import React from 'react'
import { ImageSlider } from '../ImageSlider'
import { Modal } from '../Modal'

type ImageSliderProps = {
  images: {
    id: UniqueId
    src: string
  }[]
  isShow: boolean
  onClose: () => void
  visibleImageId?: string
}

export function ImageSliderModal({
  images,
  isShow,
  onClose,
  visibleImageId,
}: ImageSliderProps) {
  return (
    <Modal isShow={isShow} onCancel={onClose} cancel='Закрыть'>
      <ImageSlider images={images} visibleImageId={visibleImageId} />
    </Modal>
  )
}
