import * as z from 'zod'

import { ApiModel } from './core'

const schema = z.object({
  terminalId: z.number(),
  customerId: z.number(),
})

export type BalanceRefillAttributes = z.infer<typeof schema>

export class BalanceRefill extends ApiModel<
  typeof schema,
  BalanceRefillAttributes
> {
  static jsonApiType = 'balance-refill'

  static schema = schema

  static async getFormMeta(): Promise<BalanceRefill> {
    const url = `${BalanceRefill.getJsonApiUrl()}/form-meta`
    const response = await BalanceRefill.effectiveHttpClient.get(url)
    const data = response?.getData()
    return new BalanceRefill(data, data.customerId)
  }
}
