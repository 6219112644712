import { Button, Typography } from '@mui/material'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { WrapperMap } from '../styled'

type Props = {
  isShow: boolean
  onCancel: () => void
}

export function MoscowDrawer({ isShow, onCancel }: Props) {
  return (
    <DrawerComponent isAutoHeight={false} isShow={isShow} onCancel={onCancel}>
      <Typography fontWeight={600}>Адрес и контакты парков:</Typography>
      <br />

      <Typography>
        Метро Лихоборы, проезд 3-й Нижнелихоборский, дом 1А{' '}
        <WrapperMap>
          (
          <Button
            href='https://yandex.ru/maps/213/moscow/?ll=37.558077%2C55.847746&mode=routes&rtext=~55.847745%2C37.558077&rtt=mt&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1Njc4MTM4OBJT0KDQvtGB0YHQuNGPLCDQnNC-0YHQutCy0LAsIDMt0Lkg0J3QuNC20L3QtdC70LjRhdC-0LHQvtGA0YHQutC40Lkg0L_RgNC-0LXQt9C0LCAx0JAiCg15OxZCFRhkX0I%2C&source=serp_navig&z=16'
            target='_blank'
            sx={{ m: 0, p: 0 }}
          >
            Яндекс-карты
          </Button>
          )
        </WrapperMap>
      </Typography>
      <Typography fontWeight={600}>Начальник колонны</Typography>
      <Typography>
        - МСК 5 Крухмалева Сабина:{' '}
        <Button href='tel:+79399008201' sx={{ m: 0, p: 0 }}>
          +7 (939) 900-82-01
        </Button>
      </Typography>
      <Typography>
        - МСК 6 Тартышов Александр:{' '}
        <Button href='tel:+79808002327' sx={{ m: 0, p: 0 }}>
          +7 (980) 800-23-27
        </Button>
      </Typography>
      <Typography>
        - МСК 7 Фаерман Владислав:{' '}
        <Button href='tel:+79399008203' sx={{ m: 0, p: 0 }}>
          +7 (939) 900-82-03
        </Button>
      </Typography>
      <Typography>
        - МСК 8 Хачатрян Вачик:{' '}
        <Button href='tel:+79399008204' sx={{ m: 0, p: 0 }}>
          +7 (939) 900-82-04
        </Button>
      </Typography>
      <br />

      <Typography>
        Метро Аннино, МКАД 33 Километр, 6 стр.1, 2 этаж (юг){' '}
        <WrapperMap>
          (
          <Button
            href='https://yandex.ru/maps/213/moscow/?ll=37.587027%2C55.578411&mode=routes&rtext=~55.578411%2C37.589087&rtt=taxi&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjYzMTA1MBJC0KDQvtGB0YHQuNGPLCDQnNC-0YHQutCy0LAsINCc0JrQkNCULCAzMy3QuSDQutC40LvQvtC80LXRgtGALCA20YExIgoNOVsWQhVLUF5C&utm_source=main_stripe_big&z=17'
            target='_blank'
            sx={{ m: 0, p: 0 }}
          >
            Яндекс-карты
          </Button>
          )
        </WrapperMap>
      </Typography>
      <Typography fontWeight={600}>Начальник колонны</Typography>
      <Typography>
        - МСК ЮГ 1 Кижапкин Максим:{' '}
        <Button href='tel:+79399008198' sx={{ m: 0, p: 0 }}>
          +7 (939) 900-81-98
        </Button>
      </Typography>
      <Typography>
        - МСК ЮГ 2 Уткин Владислав:{' '}
        <Button href='tel:+79399008199' sx={{ m: 0, p: 0 }}>
          +7 (939) 900-81-99
        </Button>
      </Typography>
      <Typography>
        - МСК ЮГ 3 Фирсов Павел:{' '}
        <Button href='tel:+79399008197' sx={{ m: 0, p: 0 }}>
          +7 (939) 900-81-97
        </Button>
      </Typography>
      <br />

      <Typography>
        Метро Авиамоторная, 4-я кабельная 2с2 (3-й Нижнелихоборский 1а){' '}
        <WrapperMap>
          (
          <Button
            href='https://yandex.ru/maps/213/moscow/?ll=37.717286%2C55.740623&mode=routes&rtext=~55.740624%2C37.717285&rtt=mt&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjcwMjMxOBJE0KDQvtGB0YHQuNGPLCDQnNC-0YHQutCy0LAsIDQt0Y8g0JrQsNCx0LXQu9GM0L3QsNGPINGD0LvQuNGG0LAsIDLRgTIiCg1_3hZCFWb2XkI%2C&source=serp_navig&z=16'
            target='_blank'
            sx={{ m: 0, p: 0 }}
          >
            Яндекс-карты
          </Button>
          )
        </WrapperMap>
      </Typography>
      <Typography fontWeight={600}>Начальник колонны</Typography>
      <Typography>
        - МСК 9 Тигинова Юлия:{' '}
        <Button href='tel:+79266301255' sx={{ m: 0, p: 0 }}>
          +7 (926) 630-12-55
        </Button>
      </Typography>
      <br />

      <Typography fontWeight={600}>Мастер приемщик СТО</Typography>
      <Typography>
        <Button href='tel:+79637137191' sx={{ m: 0, p: 0 }}>
          +7 (963) 713-71-91
        </Button>
      </Typography>
    </DrawerComponent>
  )
}
