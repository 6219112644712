import { Box, Button, Typography } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { mainBalance } from '~/entities/MainBalance'
import { CURRENCY } from '~/shared/config/constants'
import { ArrowRightIcon, PlusIcon } from '~/shared/ui/icons'
import { ProgressBlock } from '~/shared/ui/Loaders'
import { TitleWrapper, ActionsWrapper } from './staled'

type BalanceHeadingProps = {
  onPayout: () => void
  onPay: () => void
}

export function BalanceHeading({ onPayout, onPay }: BalanceHeadingProps) {
  const isPending = useStore(mainBalance.getCurrentBalanceFx.pending)

  const amount = useStoreMap(
    mainBalance.$currentBalance,
    (currentBalance) => currentBalance?.getAmount() || 0,
  )

  if (isPending) return <ProgressBlock height={158} />

  return (
    <Box>
      <TitleWrapper>
        <Typography color='aliceBlue.main' fontSize={12}>
          Мой баланс
        </Typography>
        <Typography color='aliceBlue.main' fontSize={32} fontWeight={700}>
          {amount.toLocaleString()}&#x20;
          <Typography component={'span'} fontSize={24} fontWeight={700}>
            {CURRENCY}
          </Typography>
        </Typography>
      </TitleWrapper>
      <ActionsWrapper>
        <Button
          sx={{ mr: '8px' }}
          fullWidth
          startIcon={<ArrowRightIcon />}
          variant='aliceBlueFlooded'
          onClick={onPayout}
        >
          Вывести
        </Button>
        <Button
          sx={{ ml: '8px' }}
          fullWidth
          startIcon={<PlusIcon />}
          variant='aliceBlueFlooded'
          onClick={onPay}
        >
          Пополнить
        </Button>
      </ActionsWrapper>
    </Box>
  )
}
