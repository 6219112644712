const LIME_GREEN = {
  main: '#D6F337',
}

const ALICE_BLUE = {
  main: '#F8FAFF',
}

const MIDNIGHT_BLUE = {
  main: '#021542',
}

const WHITE = {
  main: '#fff',
  '100': '#F8FAFF',
}
const BLACK = {
  main: '#000',
}

const GREY_MUTED_TEXT = {
  main: '#8E94A3',
}

const GREY_MUTED_ICON = {
  main: '#9A9A9A',
}

const CORAL = {
  main: '#F46857',
}

const GREY = {
  main: '#8E94A3',
  muted: '#8E94A3',
  shadowLight: 'rgba(0, 21, 70, .1)',
  buttonOutlineBorder: '#D4DBFF',
  buttonBackBg: 'rgba(255,255,255, .3)',
  descriptionLabel: '#969696',
  buttonLinkMuted: '#999',
  headerNavButtonBorder: '#364254',
  drawerTopLine: '#E8ECF2',
  inputBorder: '#B2BDD6',
}

const BLUE = {
  main: '#1047CA',
  light: '#E4ECFF',
  lightGrayishBlue: '#E8ECF2',
  paleBlue: '#F8FAFF',
  lightSteelBlue: '#B2BDD6',
}

export const myPalette = {
  limeGreen: LIME_GREEN,
  aliceBlue: ALICE_BLUE,
  midnightBlue: MIDNIGHT_BLUE,
  white: WHITE,
  black: BLACK,
  mutedText: GREY_MUTED_TEXT,
  dark: GREY,
  greyMutedIcon: GREY_MUTED_ICON,
  blue: BLUE,
  coral: CORAL,
}
