import { LoadingButton } from '@mui/lab'
import { Button, Stack, Typography } from '@mui/material'
import { DrawerComponent } from '~/shared/ui/Drawer'

type DeleteCardDrawerProps = {
  isShow: boolean
  onClose: () => void
  onDelete: () => void
  isLoading: boolean
}

export function DeleteCardDrawer({
  isShow,
  onClose,
  onDelete,
  isLoading,
}: DeleteCardDrawerProps) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Typography fontSize={18} fontWeight={700} mb='32px'>
        Удалить карту?
      </Typography>

      <Stack direction='row' spacing={1}>
        <Button
          fullWidth
          variant='lightSteelBlueBorder'
          size='large'
          onClick={onClose}
          disabled={isLoading}
        >
          Отмена
        </Button>
        <LoadingButton
          loading={isLoading}
          fullWidth
          variant='limeFlooded'
          size='large'
          onClick={onDelete}
        >
          Удалить
        </LoadingButton>
      </Stack>
    </DrawerComponent>
  )
}
