import { Box } from '@mui/material'

type CarImageProps = {
  src: string
}
export function CarImage({ src }: CarImageProps) {
  return (
    <Box>
      <img src={src} width='100%' height='auto' alt='mainCar' />
    </Box>
  )
}
