export function MenuCameraIcon() {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='18' fill='#021542' />
      <rect
        x='9'
        y='13.4048'
        width='16.5'
        height='10.0833'
        rx='1.83333'
        stroke='#E4ECFF'
        strokeWidth='1.2'
      />
      <circle
        cx='14.0418'
        cy='18.4466'
        r='3.20833'
        stroke='#E4ECFF'
        strokeWidth='1.2'
      />
      <path
        d='M15.4165 13.4049V12.0299C15.4165 11.7767 15.6217 11.5715 15.8748 11.5715H18.6248C18.878 11.5715 19.0832 11.7767 19.0832 12.0299V13.4049'
        stroke='#E4ECFF'
        strokeWidth='1.2'
      />
      <path
        d='M20.4585 16.1548H23.6668'
        stroke='#E4ECFF'
        strokeWidth='1.2'
        strokeLinecap='round'
      />
    </svg>
  )
}
