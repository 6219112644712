import { Box, styled } from '@mui/material'

export const Wrapper = styled(Box)`
  .slick-slider {
    .slick-track {
      display: flex;

      .slick-slide {
        border-radius: 12px;
        overflow: hidden;
        margin: 0 4px;

        & > div {
          width: 100%;
          height: 100%;
          display: flex;

          img {
            object-fit: contain;
          }
        }
      }
    }

    .slick-dots {
      display: flex !important;
      justify-content: center;
      padding: 0;
      margin: 4px 0 0;

      li {
        width: 40px;
        height: 40px;
        margin: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;

        &.slick-active {
          transform: scale(1.1);
          transition: 0.3s transform;
        }

        ::marker {
          display: none;
          content: '';
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`
