import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { ReactNode, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { FormContainer } from 'react-hook-form-mui'
import { sanitizePriceNumber } from '~/shared/lib/utils'
import { FormValues, withdrawalSchema } from './model'
import { NumericFormatElement, RemoveAllButton } from './styled'

type FormProps = {
  isSubmitting: boolean
  onSuccess: () => void
  amountAll: number
  isAmountAllLoading: boolean
  onSumChange: (sum?: number) => void
  disable: boolean
  additionalContent?: ReactNode
}

export function WithdrawalForm({
  isSubmitting,
  onSuccess,
  amountAll,
  isAmountAllLoading,
  onSumChange,
  disable,
  additionalContent,
}: FormProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(withdrawalSchema),
  })

  const { watch, reset } = form

  const amountForm = watch('amount')

  const amountError = useMemo(() => {
    const amount = sanitizePriceNumber(amountForm || 0)
    let message = ''

    if (amount > 6200) {
      message = `Максимальная сумма за разовый вывод средств 6 200 руб.`
    } else if (amount > amountAll) {
      message = `Допустимый остаток списания - ${amountAll.toLocaleString()} руб.`
    }

    return message
      ? {
          helperText: message,
          error: true,
        }
      : undefined
  }, [amountAll, amountForm])

  watch((data, { name, type }) => {
    if (type !== 'change') return
    if (name === 'amount') {
      onSumChange(sanitizePriceNumber(data.amount || 0))
    }
  })

  const handleWithdrawAll = () => {
    reset({ amount: amountAll })
    onSumChange(amountAll)
  }

  const handleOnSuccess = () => {
    if (amountError) return
    onSuccess()
  }

  return (
    <FormContainer formContext={form} onSuccess={handleOnSuccess}>
      <NumericFormatElement
        name='amount'
        label='Сумма для снятия'
        fullWidth
        suffix=' руб'
        InputProps={{
          inputProps: {
            inputMode: 'numeric',
          },
          endAdornment: (
            <RemoveAllButton
              loading={isAmountAllLoading}
              onClick={handleWithdrawAll}
              variant='text'
            >
              Снять всё
            </RemoveAllButton>
          ),
        }}
        {...amountError}
      />

      {additionalContent}

      <LoadingButton
        fullWidth
        type='submit'
        variant='limeFlooded'
        size='large'
        loading={isSubmitting}
        disabled={disable}
        data-testid='withdrawal-drawer-submit'
      >
        Вывести
      </LoadingButton>
    </FormContainer>
  )
}
