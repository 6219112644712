import * as z from 'zod'
import { ApiModel } from './core'

const attributesSchema = z.object({})
const schema = z.object({})

export type CustomerAttributes = z.infer<typeof attributesSchema>

export class Customer extends ApiModel<typeof schema, CustomerAttributes> {
  static jsonApiType = 'customers'

  static schema = schema

  static async me() {
    const url = `${Customer.getJsonApiUrl()}/me`
    const response = await Customer.effectiveHttpClient.get(url)
    return response?.getData().data
  }
}
