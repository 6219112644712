import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { DrawerComponent, useDrawer } from '~/shared/ui/Drawer'
import { KrasnodarDrawer } from './KrasnodarDrawer'
import { MoscowDrawer } from './MoscowDrawer'
import { RostovDrawer } from './RostovDrawer'
import { SaintPetersburgDrawer } from './SaintPetersburgDrawer'
import { Card, Title, TitleWrapper } from './styled'

type ParkAddressAndContactsDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function ParkAddressAndContactsDrawer({
  isShow,
  onClose,
}: ParkAddressAndContactsDrawerProps) {
  const {
    openDrawer: moscowOpen,
    closeDrawer: moscowClose,
    isDrawerShow: isMoscowShow,
  } = useDrawer()
  const {
    openDrawer: saintPetersburgOpen,
    closeDrawer: saintPetersburgClose,
    isDrawerShow: isSaintPetersburgShow,
  } = useDrawer()
  const {
    openDrawer: krasnodarOpen,
    closeDrawer: krasnodarClose,
    isDrawerShow: isKrasnodarShow,
  } = useDrawer()
  const {
    openDrawer: rostovOpen,
    closeDrawer: rostovClose,
    isDrawerShow: isRostovShow,
  } = useDrawer()

  return (
    <>
      <DrawerComponent isShow={isShow} onCancel={onClose}>
        <Card onClick={moscowOpen}>
          <TitleWrapper>
            <Title sx={{ ml: '12px' }}>Москва</Title>
          </TitleWrapper>
          <ChevronRightIcon />
        </Card>

        <Card onClick={saintPetersburgOpen}>
          <TitleWrapper>
            <Title sx={{ ml: '12px' }}>Санкт-Петербург</Title>
          </TitleWrapper>
          <ChevronRightIcon />
        </Card>

        <Card onClick={krasnodarOpen}>
          <TitleWrapper>
            <Title sx={{ ml: '12px' }}>Краснодар</Title>
          </TitleWrapper>
          <ChevronRightIcon />
        </Card>

        <Card onClick={rostovOpen}>
          <TitleWrapper>
            <Title sx={{ ml: '12px' }}>Ростов-на-Дону</Title>
          </TitleWrapper>
          <ChevronRightIcon />
        </Card>
      </DrawerComponent>

      <MoscowDrawer isShow={isMoscowShow} onCancel={moscowClose} />
      <SaintPetersburgDrawer
        isShow={isSaintPetersburgShow}
        onCancel={saintPetersburgClose}
      />
      <KrasnodarDrawer isShow={isKrasnodarShow} onCancel={krasnodarClose} />
      <RostovDrawer isShow={isRostovShow} onCancel={rostovClose} />
    </>
  )
}
