import { useStoreMap } from 'effector-react'
import { profileModel } from '~/widgets/Profile'
import { formatDateForUI } from '~/shared/lib/date'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { Label, Field } from './styled'

type DriverLicenseDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function DriverLicenseDrawer({
  isShow,
  onClose,
}: DriverLicenseDrawerProps) {
  const fullName = useStoreMap(profileModel.$driverProfile, (profile) =>
    profile?.getFullName(),
  )
  const drivingLicenseNumber = useStoreMap(
    profileModel.$driverProfile,
    (profile) => profile?.getDrivingLicenseNumber(),
  )
  const birthDate = useStoreMap(profileModel.$driverProfile, (profile) =>
    profile?.getBirthDate(),
  )
  const drivingLicenseIssueDate = useStoreMap(
    profileModel.$driverProfile,
    (profile) => profile?.getDrivingLicenseIssueDate(),
  )
  const drivingLicenseExpirationDate = useStoreMap(
    profileModel.$driverProfile,
    (profile) => profile?.getDrivingLicenseExpirationDate(),
  )
  const drivingLicenseIssuingCountry = useStoreMap(
    profileModel.$driverProfile,
    (profile) => profile?.getDrivingLicenseIssuingCountry(),
  )

  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Label>ФИО</Label>
      <Field data-testid='license-full-name'>{fullName}</Field>

      <Label>Серия и номер</Label>
      <Field data-testid='license-driving-license-number'>
        {drivingLicenseNumber}
      </Field>

      <Label>Дата рождения</Label>
      <Field data-testid='license-birth-date'>
        {birthDate ? formatDateForUI(birthDate) : '-'}
      </Field>

      <Label>Дата выдачи</Label>
      <Field data-testid='license-driving-license-issue-date'>
        {drivingLicenseIssueDate
          ? formatDateForUI(drivingLicenseIssueDate)
          : '-'}
      </Field>

      <Label>Дата окончания</Label>
      <Field data-testid='license-driving-license-expiration-date'>
        {drivingLicenseExpirationDate
          ? formatDateForUI(drivingLicenseExpirationDate)
          : '-'}
      </Field>

      <Label>Страна выдачи</Label>
      <Field data-testid='license-driving-license-issuing-country'>
        {drivingLicenseIssuingCountry}
      </Field>
    </DrawerComponent>
  )
}
