import { GlobalStyles as MuiGlobalStyles } from '@mui/material'

export const GlobalStyles = () => (
  <MuiGlobalStyles
    styles={{
      '*, *::before, *::after': {
        boxSizing: 'border-box',
      },
    }}
  />
)
