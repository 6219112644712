import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { MainLayout } from '~/widgets/layout/main'
import { MinimalLayout } from '~/widgets/layout/minimal'
import { ProtectedPage } from '~/features/auth/protected-page'
import NotFoundPage from './404'
import { ListBalancePage } from './Balance'
import { ListBankCardsPage } from './Balance/ListBankCardsPage'
import { ListBalanceHistoriesPage } from './BalanceHistories'
// import CarsPage from './cars'
// import { CarPage } from './cars/Car'
import HomePage from './Home'
import LoginPage from './login'
// import ProfilePage from './profile'
// import ProfileBalance from './profile/balance'

export const Routing: React.FC = () => {
  return (
    <Routes>
      <Route element={<ProtectedPage />}>
        <Route element={<MainLayout />}>
          <Route path='/' element={<HomePage />} id={'home'} />

          <Route path='/balance' element={<ListBalancePage />} />
          <Route path='/bank-cards' element={<ListBankCardsPage />} />

          <Route
            path='/balance-histories'
            element={<ListBalanceHistoriesPage />}
          />

          {/*// todo: delete profile*/}
          {/*<Route path='/profile' element={<ProfilePage />} />*/}
          {/*<Route path='/cars' element={<CarsPage />} />*/}
          {/*<Route path='/cars/:id' element={<CarPage />} />*/}
          {/*// todo: delete balance*/}
          {/*<Route path='/profile/balance' element={<ProfileBalance />} />*/}
        </Route>
      </Route>

      <Route element={<MinimalLayout />}>
        <Route path='/login' element={<LoginPage />} />
      </Route>

      <Route element={<MinimalLayout />}>
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
